import React from "react";
//containers:
import Window from "../Window/Window";
//components:
// import List from "../../components/List/List";
//utills:
// import { delay } from "../../utills";
//provider
import { getSocket, emit } from "../../providers/socket";
import lz from "lz-string";
// import decimal from "decimal.js/decimal";
import { translate } from "../../providers/lang";
//icons:
import { Icon } from "react-icons-kit";
import { u1F331 } from "react-icons-kit/noto_emoji_regular/u1F331";
import { rotateRight } from "react-icons-kit/fa/rotateRight";

class Stats extends Window {
	constructor(props) {
		super(props);
		this.state = {
			tab: 0, //0 = overall, 1 = current
			draggable: true,
			draggableOnMobile: false,
		};
		this.setClassName("statsWindow");
		this.setTitle(translate("stats"));
		this.onSocketResetStats = this.onSocketResetStats.bind(this);
		super.endConstructor();
	}

	onSocketResetStats(data) {
		if (typeof data === "string") data = JSON.parse(lz.decompressFromBase64(data));
		this.setData(data.stats);
		this.loaderHide();
	}

	onResetStats(e) {
		e.stopPropagation();
		const socket = getSocket();
		if (!socket) return;
		if (!socket.hasListeners("onResetStats")) socket.on("onResetStats", this.onSocketResetStats);
		emit("onResetStats", {});
		this.loaderShow();
	}

	setData(stats) {
		const obj = {};
		for (let k in stats)
			if (k.charAt(k.length - 1) !== "N") obj[k] = stats[k].toFixed(12).substr(0, 10);
			else obj[k] = stats[k];
		this.setState(obj);
		return this;
	}

	tabClick = (e, tab) => {
		e.stopPropagation();
		this.setState({ tab });
	};

	renderStats = (tab) => {
		if (typeof this.state.lossesN === "undefined") return [];
		const pre = tab ? "ses_" : "";
		const betsN = this.state[`${pre}lossesN`] + this.state[`${pre}winsN`];
		const luck = ((this.state[`${pre}payout`] / (betsN === 0 ? 1 : betsN)) * 100).toFixed(2);

		return (
			<div className="panes">
				<div className="pane">
					<label>{translate("total_wagered")}</label>
					<div>
						<b className="gold">{this.state[`${pre}bets`]}</b>
					</div>
					<label>{`${translate("bets")} / ${translate("wins")} / ${translate("losses")}`}</label>
					<div className="flex_h flex_c_c">
						<b className="gold">{betsN}</b>
						<b className="grey">/</b>
						<b className="green">{this.state[`${pre}winsN`]}</b>
						<b className="grey">/</b>
						<b className="red">{this.state[`${pre}lossesN`]}</b>
					</div>
				</div>
				<div className="pane">
					<label>{translate("total_profit")}</label>
					<div>
						<b className={parseFloat(this.state[`${pre}profit`]) < 0 ? "red" : "green"}>{this.state[`${pre}profit`]}</b>
					</div>
					<label>{translate("luck")}</label>
					<div className="flex_h flex_c_c">
						<Icon className="luckIcon" size={22} icon={u1F331} />
						<b className="green">{luck + " %"}</b>
					</div>
				</div>
			</div>
		);
	};

	renderTabs(tab) {
		return (
			<div className="tabs">
				<button onClick={(e) => this.tabClick(e, 0)} className={`btnDefault tab tab0 ${tab === 0 ? "on" : "off"}`}>
					{translate("overall")}
				</button>
				<button onClick={(e) => this.tabClick(e, 1)} className={`btnDefault m10_l tab tab1 ${tab === 1 ? "on" : "off"}`}>
					{translate("current")}
				</button>
			</div>
		);
	}

	renderContent() {
		return (
			<div className="stats">
				<button id="kkk" onClick={(e) => this.onResetStats(e)} className={`btnIcon btnReset ${this.state.tab ? "on" : "off"}`}>
					<Icon icon={rotateRight} />
				</button>
				{this.renderTabs(this.state.tab)}
				{this.renderStats(this.state.tab)}
			</div>
		);
	}
}

export default Stats;

import React from "react";
//containers:
import Window from "../Window/Window";
//components:
import List from "../../components/List/List";
//utills:
import { getTimeFromTimestamp } from "../../utills";
import lz from "lz-string";
//provider
import { getSocket, emit } from "../../providers/socket";
import decimal from "decimal.js/decimal";
import { translate } from "../../providers/lang";
//icons
import { Icon } from "react-icons-kit";
import { share as iconShare } from "react-icons-kit/fa/share";

class History extends Window {
	constructor(props) {
		super(props);
		this.state = {
			itemId: null,
			tab: 0,
			r: null,
		};
		this.delayTimer = null;
		this.onHistory = this.onHistory.bind(this);
		this.setClassName("historyWindow");
		this.setTitle(translate("history"));
		super.endConstructor();
		this.listRef = React.createRef();
		this.onShowRandom = null;
		this.onVerify = null;
		this.bet_share = false;
	}

	setConfig(data) {
		this.bet_share = data.serverConfig.bet_share;
	}

	setOnShowRandom = (fnc) => {
		this.onShowRandom = fnc;
		return this;
	};

	setOnVerify = (fnc) => {
		this.onVerify = fnc;
		return this;
	};

	show(withDelay = true) {
		const socket = getSocket();
		if (!socket) return;
		if (!socket.hasListeners("onHistory")) socket.on("onHistory", this.onHistory);
		this.setState({ history: null, hr: null });
		this.delayTimer = setTimeout(() => emit("onHistory", {}), withDelay ? 1000 : 0);
		this.loaderShow();
		return super.show();
	}

	hide() {
		if (this.delayTimer) clearTimeout(this.delayTimer);
		super.hide();
	}

	onHistory(data) {
		if (typeof data === "string") data = JSON.parse(lz.decompressFromBase64(data));
		this.setState({ history: data.history, hr: data.hr });
		this.loaderHide();
		if (!process.env || process.env.NODE_ENV !== "production") console.log(data);
	}

	expand = (e, itemId) => {
		e.stopPropagation();
		if (this.state.itemId === itemId) return;
		this.setState({ itemId: this.state.itemId === itemId ? null : itemId });
	};
	navigate = (e, el) => {
		e.stopPropagation();
		if (el.server_salt === undefined) {
			this.props.parent.fairness.onRandomizeClick(e, true, true);
			this.props.parent.fairness.setOnRandomize((data) => {
				this.show(false);
			});
		} else if (this.onVerify) this.onVerify(el);
		//window.open("https://www.google.com", "blank");
	};
	onTab = (e, tab) => {
		e.stopPropagation();
		this.setState({ tab, itemId: null }, () => {
			if (this.listRef.current) this.listRef.current.scrollToTop(0);
		});
	};
	showRandom = (element, data) => {
		if (this.state.tab === 0 && this.onShowRandom && element && this.state.itemId === `id_${data.transaction_id}`) {
			element.innerHTML = this.onShowRandom(data);
		}
		//return null;
	};
	renderRandom = (data) => {
		if (this.onShowRandom && this.state.itemId === `id_${data.transaction_id}`)
			return (
				<div
					id="randomView"
					ref={(el) => {
						this.showRandom(el, data);
					}}
				>
					{" "}
				</div>
			);
		return null;
	};
	renderContent() {
		const arr = this.state.tab === 0 ? this.state.history : this.state.hr;
		const arrR = arr
			? arr.map((el, i) => {
					if (el.win_amount === undefined) el.win_amount = 0;
					if (el.bet_amount === undefined) el.bet_amount = 0;
					const profitD = new decimal(el.win_amount).minus(el.bet_amount);
					let profit = profitD.abs().toFixed(8).substr(0, 10);
					let betAmount = new decimal(el.bet_amount).toFixed(8).substr(0, 10);
					let itemId = `id_${el.transaction_id}`;
					let visible = this.state.itemId === itemId;
					return {
						id: itemId,
						c: (
							<div
								onClick={(e) => this.expand(e, itemId)}
								className={`item ${profitD.gte(0) ? "win" : "lose"} ${visible ? "opened" : ""}`}
							>
								<div className="flex_h flex_c_b w100">
									<div className="flex_v flex_c_c">
										<div className="user m5_b">{el.player_name}</div>
										<div className="time">{getTimeFromTimestamp(el.time)}</div>
									</div>
									<div className="flex_v flex_c_c">
										<div className="amount m5_b">{betAmount}</div>
										<div className="profit">{profit}</div>
									</div>
									<div className="flex_v flex_c_c">
										<div className="currency">{el.currency}</div>
									</div>
								</div>
								{this.state.tab === 0 && (
									<div className={`dat ${visible ? "visible" : ""}`}>
										<label>{translate("client_seed")}</label>
										<div className="dat">{el.client_salt}</div>
										{el.server_salt !== undefined && (
											<>
												<label>{translate("server_seed")}</label>
												<div className="dat">{el.server_salt}</div>
											</>
										)}
										{el.nonce !== undefined && (
											<div className="flex_h flex_c_0 pairBets">
												<label>{translate("bets_made_with_pair")}</label>
												<div className="dat m10_l">{el.nonce}</div>
											</div>
										)}
										{this.renderRandom(el)}
										<div className="btmButtons flex_h w100 m5_t">
											{this.bet_share && (
												<button
													onClick={(e) => [e.stopPropagation(), this.props.parent.betShare(el)]}
													className="btnDefault btnShare p10_all"
												>
													<Icon icon={iconShare} />
												</button>
											)}
											<div className="flex1"></div>
											<button onClick={(e) => this.navigate(e, el)} className="btnDefault btnVerify p10_all">
												{translate(el.server_salt === undefined ? "randomize" : "verify")}
											</button>
										</div>
									</div>
								)}
							</div>
						),
					};
			  })
			: [];
		return (
			<div className="history">
				<div className="flex_h m10_b">
					<button onClick={(e) => this.onTab(e, 0)} className={`btnDefault w50 p10_all ${this.state.tab === 0 ? "on" : "off"}`}>
						{translate(`my_bets`)}
					</button>
					<button onClick={(e) => this.onTab(e, 1)} className={`btnDefault w50 m10_l p10_all ${this.state.tab === 1 ? "on" : "off"}`}>
						{translate(`hr_bets`)}
					</button>
				</div>
				{/* <button className="btnDefault">{translate(`jackpots`)}</button> */}
				<div className="list">{arr && <List ref={this.listRef} ren={true} data={arrR} />}</div>
			</div>
		);
	}
}

export default History;

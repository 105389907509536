export const onTest = data => {
	//console.log("onTest", data);
};
let xNonce = 0;
export const getNonce = () => {
	return xNonce++;
};
export let xCode;
export const t = _xCode => (xCode = _xCode);
export const xCodeGet = () => {
	return xCode;
};

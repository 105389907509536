import React, { PureComponent } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { config } from "../../config";

import { SoundsManager } from "../../classes/SoundsManager";
import { GameSocket } from "../../classes/GameSocket";

import Fairness from "../Fairness/Fairness";
import BetAmount from "../BetAmount/BetAmount";
import History from "../History/History";
import Stats from "../Stats/Stats";
import RollInfoWindow from "../RollInfo/RollInfoWindow";
import AutoPlay from "../AutoPlay/AutoPlay";
import Help from "../Help/Help";
import Reconnect from "../Reconnect/Reconnect";

import mobile from "is-mobile";

class Module extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {};
		this.onInit = props.onInit;
		this.loaded = { loading: true, socket: false, balance: false, collection: false };

		this.isMobile = mobile.isMobile({});
		this.isMobileOrTablet = mobile.isMobile({ tablet: true });
		this.isTablet = this.isMobileOrTablet && !this.isMobile;

		this.window = null;
		this.windowSize = { width: 0, height: 0 };

		this.fairness = null;
		this.betAmount = null;
		this.history = null;
		this.stats = null;
		this.rollInfoWindow = null;
		this.autoPlay = null;
		this.help = null;
		this.reconnect = null;

		this.sounds = new SoundsManager();
		this.gameSocket = null;

		this.onKeyDown = [];
		this.onKeyUp = [];
		this.onLoadIsDone = [];
	}

	loadDoneOf(elementName, data) {
		if (!this.loaded.loading) return true;
		if (!data) data = true;
		this.loaded[elementName] = data;

		if (true) {
			const waitKeys = [];
			for (let k in this.loaded) if (k !== "loading" && !this.loaded[k]) waitKeys.push(k);
			console.log(`wait for load: [${waitKeys.join(", ")}]`);
		}

		for (let k in this.loaded) if (!this.loaded[k]) return false;
		for (let f of this.onLoadIsDone) f(this.loaded.socket);
		this.loaded.loading = false;
		return true;
	}

	setupWindow(element) {
		this.window = element;
	}

	updateDimensions = () => {
		this.windowSize.width = this.window.clientWidth - 1;
		this.windowSize.height = this.window.clientHeight;
	};

	componentDidMount() {
		document.addEventListener("keydown", (e) => this.onKeyDownEvent(e));
		document.addEventListener("keyup", (e) => this.onKeyUpEvent(e));
		if (this.onInit) this.onInit(this);
		this.gameSocket = new GameSocket(this);
		this.gameSocket.init();
		this.updateDimensions();
	}

	componentWillMount = () => window.addEventListener("resize", this.updateDimensions);
	componentWillUnmount = () => window.removeEventListener("resize", this.updateDimensions);

	goHome() {
		const o = {
			action: "go_home",
			sender: "pandora",
		};
		window.parent.postMessage(o, "*");
	}

	betShare(data) {
		const o = {
			action: "bet_share",
			currency: data.currency,
			game_uuid: this.gameSocket.gameData.serverConfig.game_uuid,
			player_name: data.player_name,
			transaction_id: data.transaction_id,
			time: data.time,
			bet_amount: data.bet_amount,
			win_amount: data.win_amount,
			url: `${config.apiUrlFront}/rollInfo/${this.gameSocket.gameData.serverConfig.merchantId}/${data.transaction_id}`,
			sender: "pandora",
		};
		window.parent.postMessage(o, "*");
	}

	addOnLoad(fnc) {
		this.onLoadIsDone.push(fnc);
	}

	addOnKeyDown(fnc) {
		this.onKeyDown.push(fnc);
	}

	addOnKeyUp(fnc) {
		this.onKeyUp.push(fnc);
	}

	onKeyDownEvent(e) {
		if (document.activeElement && document.activeElement.type === "text") return;
		for (let f of this.onKeyDown) f(e);
	}

	onKeyUpEvent(e) {
		if (document.activeElement && document.activeElement.type === "text") return;
		for (let f of this.onKeyDown) f(e);
	}

	notification(text, options = {}) {
		const o = Object.assign(
			{
				position: "top-right",
				autoClose: false,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				type: "warning",
			},
			options
		);
		toast[o.type](text, o);
		return this;
	}

	notificationClear() {
		toast.dismiss();
		return this;
	}

	render() {
		return (
			<>
				<ToastContainer
					className="toastContainerOverride"
					toastClassName="toastOverride"
					position="top-right"
					autoClose={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnVisibilityChange
					draggable
				/>
				<div ref={(e) => this.setupWindow(e)} className={`module ${this.isMobile ? "isMobile" : ""}`} style={{ minWidth: "320px" }}>
					<Fairness onInit={(r) => (this.fairness = r)} parent={this} />
					<BetAmount onInit={(r) => (this.betAmount = r)} parent={this} />
					<History onInit={(r) => (this.history = r)} parent={this} />
					<Stats onInit={(r) => (this.stats = r)} parent={this} />
					<RollInfoWindow onInit={(r) => (this.rollInfoWindow = r)} parent={this} />
					<AutoPlay onInit={(r) => (this.autoPlay = r)} parent={this} />
					<Help onInit={(r) => (this.help = r)} parent={this} />
					<Reconnect onInit={(r) => (this.reconnect = r)} parent={this} />
				</div>
			</>
		);
	}
}

export default Module;

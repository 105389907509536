import React from "react";
import RollInfo from "./RollInfo";
import Window from "../Window/Window";
import { translate } from "../../providers/lang";
import { config } from "../../config";

class RollInfoWindow extends Window {
	constructor(props) {
		super(props);
		this.setClassName("rollInfoWindow");
		this.state = {
			transaction_id: props.transaction_id,
			api: props.api,
		};
		//this.setClassName("rollInfoWindow");
		this.setTitle(translate("rollInfo"));
		super.endConstructor();
		this.rollInfo = null;
	}

	async show(transaction_id, api = null) {
		if (!api) api = config.merchantId;
		await this.setState({ transaction_id, api });
		super.show();
		this.loaderShow();
		await this.rollInfo.getServerData();
		this.loaderHide();
	}

	renderContent() {
		return <RollInfo ref={(el) => (this.rollInfo = el)} transaction_id={this.state.transaction_id} api={this.state.api}></RollInfo>;
	}
}

export default RollInfoWindow;

import React from "react";
//containers:
import Window from "../Window/Window";
//components:
import List from "../../components/List/List";
//utills:
import { delay } from "../../utills";
//provider
import { translate } from "../../providers/lang";
//icons:
import { Icon } from "react-icons-kit";
import { ic_radio_button_checked as checked } from "react-icons-kit/md/ic_radio_button_checked";
import { ic_radio_button_unchecked as unchecked } from "react-icons-kit/md/ic_radio_button_unchecked";

class Help extends Window {
	constructor(props) {
		super(props);
		this.state = {
			tab: 0,
			points: [], //{ url, text }
			rules: "",
			checked: 0
		};
		this.delayTimer = null;
		this.setClassName("helpWindow");
		this.setTitle(translate("how_play"));
		super.endConstructor();
		this.listRef = React.createRef();
	}

	onLoad(data) {
		let house_edge = data.serverConfig.houseEdge.toString();

		let text = this.state.rules;
		text = text.replace(/\{house_edge\}/g, house_edge);
		this.setRules(text);
	}

	show() {
		super.show();
		this.setState({ checked: 0, tab: 0 });
	}

	addSlide(text, urlImage) {
		this.state.points.push({ url: urlImage, text });
		this.setState({ points: this.state.points });
		return this;
	}

	setRules(text) {
		this.setState({ rules: text });
		return this;
	}

	onTab = (e, tab) => {
		e.stopPropagation();
		this.setState({ tab }, () => {
			// this.listRef.current.scrollToTop(0);
		});
	};
	check = (e, i) => {
		e.stopPropagation();
		this.setState({ checked: i });
	};
	onImgLoad = async e => {
		const target = e.target;
		target.classList.remove("loaded");
		await delay(300);
		target.classList.add("loaded");
	};
	onNext = e => {
		e.stopPropagation();
		if (this.state.checked === this.state.points.length - 1) {
			this.hide();
		} else this.setState({ checked: this.state.checked + 1 });
	};
	renderContent() {
		const arrR = [{ id: 1, c: <p>{this.state.rules}</p> }];
		return (
			<div className="help">
				<div className="flex_h m10_b">
					<button onClick={e => this.onTab(e, 0)} className={`btnDefault w50 p10_all ${this.state.tab === 0 ? "on" : "off"}`}>
						{translate(`how_play`)}
					</button>
					<button onClick={e => this.onTab(e, 1)} className={`btnDefault w50 m10_l p10_all ${this.state.tab === 1 ? "on" : "off"}`}>
						{translate(`rules`)}
					</button>
				</div>
				{this.state.tab === 0 || this.state.rules === "" ? (
					this.state.points.length > 0 ? (
						<div className="flex_v frame">
							<div className="slide flex_h flex_c_c">
								<img onLoad={e => this.onImgLoad(e)} src={this.state.points[this.state.checked]["url"]} alt="" title="" />
							</div>
							<div className="text flex_h flex_c_c">
								<p>{this.state.points[this.state.checked]["text"]}</p>
							</div>
							<div className="flex_h flex_c_c points">
								{this.state.points.map((el, i) => {
									return (
										<button key={`point_${i}`} onClick={e => this.check(e, i)} className="btnIcon btnPoint m5_all">
											<Icon icon={this.state.checked === i ? checked : unchecked} />
										</button>
									);
								})}
							</div>
							<button onClick={this.onNext} className="btnDefault btnNext p10_all m5_t">
								{translate(this.state.checked === this.state.points.length - 1 ? "start_playing" : "next_step")}
							</button>
						</div>
					) : null
				) : (
					<div className="list">{arrR && <List ref={this.listRef} ren={true} data={arrR} />}</div>
				)}
			</div>
		);
	}
}

export default Help;

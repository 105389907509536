import React, { Component, lazy, Suspense } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { GameReactComponent } from "../containers/GameReactComponent/GameReactComponent";
import { GameReactComponentHTML } from "../containers/GameReactComponent/GameReactComponentHTML";
import { translate } from "./lang";
//containers:
const RollInfo = lazy(() => import("../containers/RollInfo/RollInfo"));
//GAMES:
// import Tetris from "../games/tetris/Tetris";
const GameExample = lazy(() => import("../games/gameExample"));
const DragonsBless = lazy(() => import("../games/dragonsBless"));
const Roulette = lazy(() => import("../games/roulette"));
const Sicbo = lazy(() => import("../games/sicbo"));
const Plinko = lazy(() => import("../games/plinko"));
const G777 = lazy(() => import("../games/g777"));
const Dice = lazy(() => import("../games/dice"));
const GoblinsBox = lazy(() => import("../games/goblinsBox"));

class Router extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		React.GameReactComponent = GameReactComponent;
		React.GameReactComponentHTML = GameReactComponentHTML;
		this.other = [{ c: RollInfo, path: "/rollInfo/:api/:transaction_id" }];
		this.games = [
			{ name: "gameExample", game: GameExample },
			{ name: "goblinsBox", game: GoblinsBox },
			{ name: "roulette", game: Roulette },
			{ name: "dragonsBless", game: DragonsBless },
			{ name: "sicbo", game: Sicbo },
			{ name: "plinko", game: Plinko },
			{ name: "g777", game: G777 },
			{ name: "dice", game: Dice },
		];
	}

	renderOtherRoute = (el, i) => {
		return (
			<Route
				key={el.path}
				path={el.path}
				render={({ match }) => (
					<Suspense fallback={<div>{`${translate("loading").toUpperCase()} ...`}</div>}>
						<el.c {...match.params} />
					</Suspense>
				)}
			/>
		);
	};
	renderRoute = (el, i) => {
		return (
			<Route
				key={el.name}
				path={`/${el.name}/:api/:session_id`}
				render={({ match }) => (
					<Suspense fallback={<div>{`${translate("loading").toUpperCase()} ...`}</div>}>
						<el.game api={match.params.api} session_id={match.params.session_id} />
					</Suspense>
				)}
			/>
		);
	};
	render() {
		return (
			<Switch>
				{this.games.map((el, i) => this.renderRoute(el, i))}
				{this.other.map((el, i) => this.renderOtherRoute(el, i))}
			</Switch>
		);
	}
}

export default withRouter(Router);

import React, { Component } from "react";

import { getSocket } from "../../providers/socket";
import Collection from "../../classes/Collection";
import { isProd, config } from "../../config";
import Module from "../Module/Module";
import { translate } from "../../providers/lang";
import ReactDOMServer from "react-dom/server";
import { hasParent } from "../../utills";

//icons:
import { Icon } from "react-icons-kit";
//import { chevronRight as arrowRight } from "react-icons-kit/fa/chevronRight";
import { ic_volume_mute as soundOff } from "react-icons-kit/md/ic_volume_mute";
import { ic_volume_up as soundOn } from "react-icons-kit/md/ic_volume_up";
//import { balanceScale as iconFairness } from "react-icons-kit/fa/balanceScale";
import { ic_verified_user as iconFairness } from "react-icons-kit/md/ic_verified_user";
import { lineChart as iconStats } from "react-icons-kit/fa/lineChart";
import { thList as iconHistory } from "react-icons-kit/fa/thList";
import { question as iconFaq } from "react-icons-kit/fa/question";
//import { ic_casino } from "react-icons-kit/md/ic_casino";
import { home } from "react-icons-kit/fa/home";

/*
this.gameData = {
	active:false,		//არის false სანამ ველოდებით სერვერის პასუხს ბრძანებებზე: onBet, onAction ან onEnd		
	isAuto: false,		//არის თუ არა ჩართული Auto	
	betAmount,			//დაწყებული ან ბოლოს ნათამაშები თამაშის bet თანხა (onLoad, onAction, onBet, onWin)
	winAmount,			//დასრულებული (ბოლოს ნათამაშები) თამაშში მოგებული თანხა (onLoad, onWin)
	playCount: 0,		//playCount იწყება 0 დან და იზრდება ყოველ onBet_ზე და ასევე იზრდება onWin_ზე
	steps: [],			//არა fast თამაშებში, მოთამაშის გაკეთებული სვლების/მოქმედებების მასივი (უკვე ნათამაშები ნაბიჯები)
	results: [],		//steps_ის თითოეულ step_ზე მიღებული random result_ი
	getLastStep: fnc()	//ფუნქცია გამოძახებისას აბრუნებს steps_ის ბოლო ელემენტს (არქონის შემთხვევაში null_ს)
	choice: [],			//მოთამაშის მიერ (უკვე ან წინა ჯერზე) გაკეთებული random_ის პროგნოზი (როგორიცაა ფიშკების დალაგება)
	random: [],			//ბოლოს ამოსული რანდომის ინფორმაცია
	randomHistory: [],	//{value:any, result:1} ბოლო რამდენიმე ნათამაშების random_ები და შედეგები (ყველაზე ახალის ინდექსია 0) 
						//result : 1 --> მოგება;	result : -1 --> წაგება;		result:0 --> bet და win თანხები ტოლია
						//თუ რამდენი რანდომი იყოს სიაში ეთითება აქ: config.games.gameName.random.historySize
	started: false,		//არა fast თამაში დაწყებულია თუ არა
	config: 0,			//კონფიგურაცია რომელზეც დამოკიდებულია თამაშის random_ი
	stats: {			//სტატისტიკა (ses_ რასაც უწერია განულებადია) (N_ით რაც მთავრდება მთელი რიცხვია)
		bets: 10.27
		payout: 10.27
		profit: 10.27
		ses_bets: 10.27
		ses_payout: 10.27
		ses_profit: 10.27
		ses_winsN: 20
		winsN: 20
		losses: 10.27
		lossesN: 20
		ses_losses: 10.27
		ses_lossesN: 20
	},
	serverConfig:null	//{ bet:{ min, max, afterDot, hrAmount }, houseEdge, bet_share, merchantId, game_uuid }
	result: 0,			//ბოლო ნათამაშების რეზულტატი (-1 : lose;     0:nothing;      1:win)
	lose: false,		//ბოლო ნათამაშების შედეგი იყო თუ არა წაგება
	win: false,			//ბოლო ნათამაშების შედეგი იყო თუ არა მოგება
	lastWinAmount: 0,	//ბოლოს მოგებული თანხა
	lastBetAmount: 0,	//ბოლოს ბოლოს გაკეთებული bet_ის თანხა
	amountInWay: null	//{ amountIn, amountOut, delta, active }
						//თამაშის შედეგის მიღებისას თანხის ცვლილების ობიექტი 
						//(საშუალებას იძლევა ბალანსი აისახოს მოგვიანებით მაგალითად რაიმე ანიმაციის დასრულების შემდეგ)
};
*/

export class GameReactComponentHTML extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loaded: false,
			isMute: false,
		};

		this.module = null;
		this.gameData = null; //არის null სანამ არ მოხდება სოკეტების ხდომილება "onLoad"

		//HTML კომპონენთები
		this.components = {
			menu: { home: null, mute: null, fireness: null, stats: null, history: null, auto: null, help: null },
			betAmount: { amount: null, currency: null },
			balance: { amount: null, currency: null },
		};

		this.balanceTask = null;
		this.betAmountTask = null;

		config.apiUrlBack = config.http.urlBack.replace("*", props.api);
		config.apiUrlFront = config.http.urlFront.replace("*", props.api);

		getSocket(props.session_id, config.apiUrlBack);
		Collection.awake();
	}

	collection(name, src) {
		return new Collection(name, src);
	}

	//-------------------------------------------------------------------------------------------------------

	//სრულდება HTML ჩატვირთვის შემდეგ (უნდა გამოვიყენოთ componentDidMount_ის მაგივრად)
	onComponentDidMount() {}

	//სრულდება როდესაც მორჩა ყველაფრის ჩატვირთვა და მზადაა თამაში დასაწყებათ
	onLoad(data) {}

	onShowHistoryRandom(data) {
		//data.steps
		//data.config
		//data.random
		return ReactDOMServer.renderToStaticMarkup(<div>r a n d o m</div>);
	}

	onVerify(data) {
		// let urlBody = "https://codepen.io/pandoragames/pen/";
		// let url = `${urlBody}?server_seed=${btoa(data.server_salt)}&client_seed=${btoa(data.client_salt)}&nonce=${btoa(data.nonce)}&config=${btoa(
		// 	data.config
		// )}`;
		// window.open(url, "blank");
	}

	//-------------------------------------------------------------------------------------------------------

	//bet ის გაგზავნის მაგალითი data = { amount: this.module.betAmount.value, config: this.gameData.config }
	doBet(data, e) {
		if (e) e.stopPropagation();
		if (this.gameData && this.gameData.active && !this.gameData.started) this.module.gameSocket.emitBet(data);
		else console.log(`no bet => active: ${this.gameData.active}, started: ${this.gameData.started}`);
	}

	//არა fast დაწყებულ თამაშსი აჩევანის გაკეთების გაგზავნის მაგალითი data = { step: this.some_choice }
	doAction(data, e) {
		if (e) e.stopPropagation();
		if (this.gameData && this.gameData.active && this.gameData.started) this.module.gameSocket.emitAction(data);
		else console.log(`no action => active: ${this.gameData.active}, started: ${this.gameData.started}`);
	}

	//არა fast დაწყებული თამაშის შეწყვეტის მოთხოვნის გაგზავნის მაგალითი data = {}
	doEnd(e) {
		if (e) e.stopPropagation();
		if (this.gameData && this.gameData.active && this.gameData.started) this.module.gameSocket.emitEnd({});
		else console.log(`no end => active: ${this.gameData.active}, started: ${this.gameData.started}`);
	}

	//-------------------------------------------------------------------------------------------------------

	//bet თანხის მაჩვენებელ ველში თანხის და ვალუტის ჩვენება
	onShowBetAmount(amountText) {
		if (!this.components.betAmount.amount) {
			if (!this.betAmountTask) this.betAmountTask = setInterval(() => this.onShowBetAmount(), 500);
			return;
		}
		if (this.betAmountTask !== null) {
			clearInterval(this.betAmountTask);
			this.betAmountTask = null;
		}
		this.components.betAmount.amount.innerHTML = this.module.betAmount.text;
		this.components.betAmount.currency.innerHTML = this.module.betAmount.getCurrency();
	}

	//ბალანსის მაჩვენებელ ველში თანხის და ვალუტის ჩვენება
	onShowBalance(balance) {
		if (!this.components.balance.amount) {
			if (!this.balanceTask) this.balanceTask = setInterval(() => this.onShowBalance(), 500);
			return;
		}
		if (this.balanceTask !== null) {
			clearInterval(this.balanceTask);
			this.balanceTask = null;
		}
		this.components.balance.amount.innerHTML = this.module.betAmount.getBalanceText();
		this.components.balance.currency.innerHTML = this.module.betAmount.getCurrency();
	}

	//ხმის გათიშვის ღილაკის ჩვენება (აქტივაციისას/დეაქტივაციისას)
	onShowMute(isMute) {
		this.setState({ isMute });
	}

	//-------------------------------------------------------------------------------------------------------

	componentDidMount() {
		this.module.betAmount.addOnChange((amountText) => this.onShowBetAmount(amountText));
		this.module.betAmount.addOnBalance((balance) => this.onShowBalance(balance));
		Collection.onLoad = () => this.module.loadDoneOf("collection");
		this.onComponentDidMount();
		this.module.addOnLoad((data) => this.onLoadDone(data));
	}

	onLoadDone(data) {
		if (isProd() && !hasParent()) return;

		this.gameData = this.module.gameSocket.gameData;

		//როდესაც History_ში ჩანაწერს გავშლით დეტალურად საჭიროა თამაშმა დააგენერიროს Random_ის შედეგიც ვიზუალურად
		//დეტალების გახსნისას მოცემული ხდომილება სრულდება და რა html_საც დააბრუნებს ის ჩაჯდება დეტალურ ინფოში
		this.module.history.setOnShowRandom(this.onShowHistoryRandom);

		//History_ში Verify_ღილაკზე ხელის დაჭერისას სრულდება მოცემული ხდომილება
		//რომელმაც უნდა ააწყოს შემოწმების საიტის ლინკი და გახსნას
		this.module.history.setOnVerify(this.onVerify);

		this.setState({ loaded: true }, () => this.onLoad(data));
	}

	onMute(e) {
		e.stopPropagation();
		this.module.sounds.play("tap").setMute().play("tap");
		this.onShowMute(this.module.sounds.mute);
	}

	onOpenSection(e, sectionName) {
		e.stopPropagation();
		this.module.sounds.play("tap");
		this.module[sectionName].show();
	}

	//-------------------------------------------------------------------------------------------------------
	renderMenuButtonEffect() {
		return (
			<>
				<div className="animateLeft"></div>
				<div className="animateRight"></div>
				<div className="animateBottom"></div>
				<div className="animateTop"></div>
			</>
		);
	}
	renderHomeButton() {
		return (
			<button onTouchStart={() => {}} onClick={(e) => this.module.goHome(e)} ref={(el) => (this.components.menu.home = el)}>
				{this.renderMenuButtonEffect()}
				<Icon size={22} icon={home} />
			</button>
		);
	}

	renderMuteButton() {
		return (
			<button onTouchStart={() => {}} onClick={(e) => this.onMute(e)} ref={(el) => (this.components.menu.mute = el)}>
				{this.renderMenuButtonEffect()}
				<Icon size={22} icon={this.state.isMute ? soundOff : soundOn} />
			</button>
		);
	}

	renderFairnessButton() {
		return (
			<button
				onTouchStart={() => {}}
				onClick={(e) => this.onOpenSection(e, "fairness")}
				ref={(el) => (this.components.menu.fairness = el)}
			>
				{this.renderMenuButtonEffect()}
				<Icon size={22} icon={iconFairness} />
			</button>
		);
	}

	renderStatsButton() {
		return (
			<button onTouchStart={() => {}} onClick={(e) => this.onOpenSection(e, "stats")} ref={(el) => (this.components.menu.stats = el)}>
				{this.renderMenuButtonEffect()}
				<Icon size={22} icon={iconStats} />
			</button>
		);
	}

	renderHistoryButton() {
		return (
			<button onTouchStart={() => {}} onClick={(e) => this.onOpenSection(e, "history")} ref={(el) => (this.components.menu.history = el)}>
				{this.renderMenuButtonEffect()}
				<Icon size={22} icon={iconHistory} />
			</button>
		);
	}

	renderAutoPlayButton() {
		if (typeof this.components.menu.auto !== "undefined")
			return (
				<button
					onTouchStart={() => {}}
					onClick={(e) => this.onOpenSection(e, "autoPlay")}
					ref={(el) => (this.components.menu.auto = el)}
				>
					{this.renderMenuButtonEffect()}
					<b>{translate("auto")}</b>
				</button>
			);
		else return null;
	}

	renderHelpButton() {
		return (
			<button onTouchStart={() => {}} onClick={(e) => this.onOpenSection(e, "help")} ref={(el) => (this.components.menu.help = el)}>
				{this.renderMenuButtonEffect()}
				<Icon size={22} icon={iconFaq} />
			</button>
		);
	}

	renderMenu() {
		return (
			<div className="menu">
				<div className="buttons">
					{this.renderHomeButton()}
					{this.renderMuteButton()}
					{this.renderFairnessButton()}
					{this.renderStatsButton()}
					{this.renderHistoryButton()}
					{this.renderAutoPlayButton()}
					{this.renderHelpButton()}
				</div>
				{this.renderBalance()}
			</div>
		);
	}

	renderBetAmount() {
		const components = this.components.betAmount;
		return (
			<button className="betAmountButton" onClick={(e) => this.onOpenSection(e, "betAmount")}>
				{this.renderMenuButtonEffect()}
				<label className="amount" ref={(el) => (components.amount = el)}></label>
				<label className="currency" ref={(el) => (components.currency = el)}></label>
			</button>
		);
	}

	renderBalance() {
		const components = this.components.balance;
		return (
			<div className="balance">
				<label className="amount" ref={(el) => (components.amount = el)}></label>
				<label className="currency" ref={(el) => (components.currency = el)}></label>
			</div>
		);
	}

	renderLoader() {
		return <div style={{ color: "#ffffff", fontSize: "36px" }}>{translate("loading")}</div>;
	}

	renderContent() {}

	render() {
		return (
			<>
				<Module ref={(el) => (this.module = el)} />
				{this.state.loaded ? this.renderContent() : this.renderLoader()}
			</>
		);
	}
}

import axios from "axios";

export const _axios = axios.create({
	baseURL: "",
	timeout: 15000,
});

_axios.interceptors.response.use(
	(response) => response.data,
	(error) => error.response.data
);

_axios.setHeaders = (o) => {
	Object.keys(o).forEach((key) => {
		console.log("header: ".green, key, o[key]);
		_axios.defaults.headers.common[key] = o[key];
	});
};

export default class Collection {
	constructor(name, src) {
		this.frames = []; //[ {x, y, width, height}, ...]
		this.loaded = false;
		this.name = name;
		this.src = src;
		this.segment = { x: 0, y: 0, width: 0, height: 0 };
		this.count = 0;
		this.countXY = { x: 0, y: 0 };
		this.size = { x: 0, y: 0 };
		this.sourceSize = { x: 0, y: 0 };
		this.separator = { x: 0, y: 0 };
		if (Collection[name]) console.log(`Collection name is duplicated "${name}"`);
		else Collection[name] = this;
		Collection.__count++;
		Collection.__loadingCount++;
		this.loadSource(src);
	}

	get(index = 0) {
		return this.frames[index];
	}

	isLast(index = 0) {
		return index === this.frames.length - 1;
	}

	setOne(frameSizeX, frameSizeY, segmentX = 0, segmentY = 0) {
		return this.setSegment(segmentX, segmentY)
			.setCount(1, 1, 1)
			.setFrameSize(frameSizeX, frameSizeY, 1);
	}

	setRowFrames(frameSizeX, frameSizeY, separatorX = 0, count = 0) {
		return this.setSeparator(separatorX, 0).setFrameSize(frameSizeX, frameSizeY, count);
	}

	setSegment(x, y, widthOrRight, heightOrBottom) {
		this.segment.x = x;
		this.segment.y = y;
		this.segment.width = widthOrRight;
		this.segment.height = heightOrBottom;
		return this.save();
	}

	setFrameSize(x, y, count = 0) {
		this.size.x = x;
		this.size.y = y;
		this.count = count;
		return this.save();
	}

	setSeparator(x, y) {
		this.separator.x = x;
		this.separator.y = y;
		return this.save();
	}

	setCount(x, y, count = 0) {
		this.countXY.x = x;
		this.countXY.y = y;
		this.count = count;
		return this.save();
	}

	add(x, y, width, height) {
		if (this.loaded) {
			this.frames.push({ x, y, width, height });
			this.count++;
		}
		return this;
	}

	save() {
		if ((this.countXY.x === 0 && this.size.x === 0) || (this.countXY.y === 0 && this.size.y === 0)) return this;
		if (this.segment.width === 0 || this.segment.height === 0) return this;
		if (this.countXY.x === 0) this.countXY.x = Math.floor((this.segment.width - this.size.x) / (this.size.x + this.separator.x)) + 1;
		if (this.countXY.y === 0) this.countXY.y = Math.floor((this.segment.height - this.size.y) / (this.size.y + this.separator.y)) + 1;
		if (this.size.x === 0) this.size.x = (this.segment.width - this.separator.x * (this.countXY.x - 1)) / this.countXY.x;
		if (this.size.y === 0) this.size.y = (this.segment.height - this.separator.y * (this.countXY.y - 1)) / this.countXY.y;
		if (this.count === 0) this.count = this.countXY.x * this.countXY.y;
		this.frames = [];
		for (let i = 0; i < this.count; i++) {
			let x = i % this.countXY.x;
			let y = Math.floor(i / this.countXY.x);
			this.frames.push({
				x: x * (this.size.x + this.separator.x) + this.segment.x,
				y: y * (this.size.y + this.separator.y) + this.segment.y,
				width: this.size.x,
				height: this.size.y
			});
		}
		return this;
	}

	loadSource(src) {
		var img = new Image();
		img.onload = () => {
			this.sourceSize.x = img.width;
			this.sourceSize.y = img.height;
			if (this.segment.width <= 0) this.segment.width += this.sourceSize.x;
			if (this.segment.height <= 0) this.segment.height += this.sourceSize.y;
			this.loaded = true;
			this.save();
			if (--Collection.__loadingCount <= 0) Collection.onLoad();
			img.remove();
		};
		img.src = src;
	}
}

Collection.awake = () =>
	setTimeout(() => {
		if (Collection.__count === 0) Collection.onLoad();
	}, 500);

Collection.__count = 0;
Collection.__loadingCount = 0;
Collection.onLoad = () => {};

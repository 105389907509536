import { keywords } from "./keywords";

export const keywords2 = {
	en: {
		loading: "Please, wait...",
		choose_difficulty: "Choose difficulty",
		stats: "Statistics",
		min: "Min",
		max: "Max",
		half: "1/2",
		double: "2X",
		fairness: "Fairness",
		my_bets: "My bets",
		hr_bets: "High rollers",
		every: "Every",
		streak: "Streak",
		to_base: "To base",
		increase_by: "Increase by",
		decrease_by: "Decrease by",
		reverse_bet: "Reverse",
		stop_auto: "Stop",
		automated_betting: "Automated betting",
		speed: "Speed",
		limits: "Limits",
		reconnect: "Reconnect",
		limit_number_of_bets_by: "Number of bets",
		stop_if_balance_under: "Balance under",
		stop_if_balance_over: "Balance over",
		stop_if_bet_amount_over: "Bet amount over",
		auto_roll: "Auto roll",
		noBalance: "Insufficient funds",
		balanceUnder: "Stop on balance under",
		balanceOver: "Stop on balance over",
		betsCount: "Bets count limit reached",
		amountOver: "Bet amount over limit reached",
		amountOverMax: "Bet amount passed max bet amount level",
		noBetAmount: "Bet is not placed",
		lossesStop: "Stop on lose",
		winsStop: "Stop on win",
		remoteStop: "Auto has stopped from another window",
		anotherAuto: "Auto has stopped as another auto instance is already running",
		anotherAutoStop: "Other instance of auto has stopped",
		play: "PLAY",
		auto: "AUTO",
		stop: "STOP",
		how_play: "How play",
		rules: "Rules",
		low: "LOW",
		medium: "MEDIUM",
		high: "HIGH",
		income: "Income",
		win_amount: "Won amount",
		more_than: "More than",
		less_than: "Less than",
		rollInfo: "Roll info",
		dice_1:
			"Choose the Bet Amount, by clicking the bet section, the popup menu will help you to define min bet, either divide by half or double the current bet, or set the maximum possible balance;",
		dice_2:
			"Choose the threshold by clicking 'Roll Under/Over' section, by defining the threshold number, which varies between 0.01 and 99.98, with respective probabilities and payouts, higher the win probability lower the payout and vice versa;",
		dice_3:
			"Alternatively the user can choose 'PAYOUT' section, by defining the payout, which varies between 1.001 and 9700, with respective probabilities and threshold number, higher the payout lower the win probability and vice versa;",
		dice_4:
			"The user can also choose 'CHANCE' section, by defining the chance, which varies between 0.01% and 98%, with respective payouts and threshold number, higher the win probability lower the payout and vice versa;",
		dice_5:
			"The user also has the possibility to define the threshold by dragging the slider right or left, if the slider is dragged to the winning zone (green:  winning zone, red: loosing zone), the payout will rise and chances decrease;",
		dice_6: "The user can swap the zones by clicking the arrow button placed on the left of the threshold slider;",
		dice_7: "After all parameters have been defined the user can click the ROLL button and enjoy the result;",

		game_is_deactive: "This game is currently turned off.",
		g777_1:
			"Choose the Bet Amount, by clicking the bet section, the popup menu will help you to define min bet, either divide by half or double the current bet, or set the maximum possible balance;",
		g777_2:
			"After the bet amount has been defined you can click the SPINN Button and wait for the results, the result depends on the combination of the middle wheel signs;",
		g777_3:
			"The result is defined only out of the combination of middle signs , neither top nor bottom line signs have any impact on the results; The winning combination summary can be viewed on the next slides;",
		g777_4: "The combination of two similar signs, the estimated payout totals around 2 X;",
		g777_5: "The combination of golds bars, no matter the  sequence or bar signs similarity, the estimated payout varies between 7 and 8 X;",
		g777_6: "The combination of three similar signs the estimated payout totals around 26 X;",
		g777_7: "The combination of three similar bar signs the estimated payout totals around 42 X;",
		g777_8:
			"The combination of growing bars (one bar, two bars, three bars OR three bars, two bars, one bar) the estimated payout totals around 84 X;",
		g777_9: "The combination of three 7s  the estimated payout totals around 250 X;",
		roulette_1:
			"Choose the chip value, by clicking the bet section, the popup menu will help you to define the min value, either divide by half or double the current one, or set the maximum possible balance;",
		roulette_2:
			"Betting is done by placing the chips on desired sections, the chip value is determined by the player, as explained in section number one, each section has corresponding payout, higher the payout lower the win chance, for example individual number has estimated payout of 36x, betting on color section has payout of 2x , the payouts may vary depending on the house edge set by the games distributor;",
		roulette_3:
			"If the user wishes to clear the placed bets one can click the CLEAR button and all chips will be cleared from the Sic Bo board;",
		roulette_4:
			"After the chips have been placed on various sections , the user can click the button ROLL and the roulette will start spinning alongside with the little ball spinning in an opposite direction;",
		roulette_5: "The result is defined as a result of fallen ball in any roulette pocket out of 37 various ones;",
		roulette_6: "After the dices have been rolled the user can repeat the latest bet strategy by clicking the button REPEAT;",
		sicbo_0:
			"Choose the chip value, by clicking the bet section, the popup menu will help you to define the min value, either divide by half or double the current one, or set the maximum possible balance;",
		sicbo_2:
			"As Sic Bo has vide variances of bets, each of them have unique payouts so this button shows or hides payouts for each bet section;",
		sicbo_3:
			"Above is given an estimated payout for each section, the exact payout depends on the game distributor and its corresponding house edge;",
		sicbo_4:
			"Betting is done by placing the chips on desired sections, the chip value is determined by the player, as explained in section number one, each section has corresponding payout, higher the payout lower the win chance;",
		sicbo_5:
			"If the user wishes to clear the placed bets one can click the CLEAR button and all chips will be cleared from the Sic Bo board;",
		sicbo_6: "After the chips have been placed on various sections , the user can click the button roll and wait for the results;",
		sicbo_7: "The result is derived out of the final combination of three dices;",
		sicbo_8: "After the dices have been rolled the user can repeat the latest bet strategy by clicking the button REPEAT",
		plinko_1:
			"Choose the chip value, by clicking the bet section, the popup menu will help you to define the min value, either divide by half or double the current one, or set the maximum possible balance;",
		plinko_2:
			"Before starting the game the user can adjust the risks  of the game and choose 3 different modes: low, medium and high risk mode, during the high risk mode side basket odds are increased but central one are decreased; The number of cells can also be adjusted , less number of cells mean a few obstacles , however the odds of the baskets are reduced;",
		plinko_3: "Above is given the possible scenario examples, for low medium and high risk modes  with various numbers of cells;",
		plinko_4: "After the bet amount,  strategy and scenario has been defined the user can start to play by clicking PLAY button;",
		plinko_5:
			"By clicking on the button “PLAY,” the ball will fall, it will hit obstacles on its way and the ball will change the direction. Finally the ball will fall in one of the Baskets and the bet will be multiplied by basket odd. It is possible to drop several balls simultaneously, by  clicking the PLAY button multiple times. All baskets have assigned odds that can be either less than 1, equal or more than 1, the maximum of is limited to 1000;",
		plinko_6:
			"The game also has auto betting options , where the user can define the number of bets, and game balance limitations (Stop on balance under or stop on balance over);",
		plinko_7: "When the game is on auto betting mode the balls fall simultaneously , and there are multiple outcomes at the same time;",
		dragonsBless_1:
			"The game starts by choosing the play mode among 5 different: easy, medium, hard , extreme and nightmare. EASY MODE: UNBORN DRAGON - 4 tiles: 3 winning tiles and 1 losing tile; MEDIUM MODE: JUST BORN DRAGON -  3 tiles: 2 winning tiles, 1 losing tile; HARD MODE: BABY DRAGON - 2 tiles: 1 winning tile, 1 losing tile; EXTREME MODE: TWO HEADED DRAGON - 3 tiles: 1 winning tile, 2 losing tiles; NIGHTMARE MODE: TRHEE HEADED DRAGON  -  4 tiles: 1 winning tile, 3 losing tiles;",
		dragonsBless_2:
			"Since the play mode is defined, choose the Bet Amount, by clicking the bet section, the popup menu will help you to define min bet, either divide by half or double the current bet, or set the maximum possible balance;",
		dragonsBless_3: "After the bet amount has been defined you can click the Play Button and start suvival campaign;",
		dragonsBless_4:
			"The mission of the player is to break a tile and find gem and move to the next step and pick another gem, if you find scratch instead of gem the game is lost and collected treasure is lost;",
		dragonsBless_5: "During the game the user can finish the play campaign at any level and claim the won amount, by clicking END button",
	},
};
for (let k1 in keywords2) for (let k2 in keywords2[k1]) keywords[k1][k2] = keywords2[k1][k2];

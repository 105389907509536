import React, { Component } from "react";
import Loader from "../../components/Loader/Loader";
import { config } from "../../config/index";
import { _axios } from "../../providers/axios";
import { translate } from "../../providers/lang";
import { getDayTimeFromTimestamp } from "../../utills";

//components:
import List from "../../components/List/List";

// import "../../styles/index.scss";
class RollInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			data: null,
		};
		this.goToVerify = null;
	}
	componentDidMount = () => {
		this.getServerData().then(() => {
			this.setState({ loading: false });
		});
	};
	getServerData = async () => {
		const { api, transaction_id } = this.props;
		if (!typeof api === "string" || !typeof transaction_id === "string" || !transaction_id || !api) return null;
		const url = config.http.urlBack.replace("*", api);
		const o = await _axios.post(`${url}/api/rollInfo`, { transaction_id });
		if (typeof o !== "object" || o === null) return null;
		if (o.error) return null;
		this.setState({ data: o });

		try {
			await import(`../../games/${o.game_uuid}/${o.game_uuid}.scss`);
		} catch {}
		const { randomView, goToVerify } = await import(`../../games/${o.game_uuid}/external`);
		this.goToVerify = goToVerify;
		document.getElementById("rollInfo").innerHTML = randomView(o);
		/*
		{
			transaction_id: '5ef1d64d63f02b2ba42591a6',
			server_salt: 'b4a2b4f5-670c-4ee5-af08-ed06509e8ecc',
			client_salt: 'f6da2841',
			nonce: 10,
			steps: [],
			choice: { rollNumber: 1142, payout: 8.493, over: false },
			currency: 'btc',
			bet_amount: 1e-8,
			win_amount: 0,
			time: 1592920682336,
			game_uuid: 'dice',
			random: 6063,
			player_name: 'player name'
			}
		*/

		//console.log("GOT DATA: ", randomView, typeof randomView);
	};

	renderInfo = () => {
		return (
			<>
				{this.state.data && <h1>{translate(this.state.data.game_uuid)}</h1>}

				<div className={`data ${this.state.loading ? "off" : "on"}`}>
					<div id="rollInfo"></div>
				</div>
			</>
		);
	};
	renderGeneralInfo = () => {
		const renderItem = (title, value, className = "") => {
			return (
				<figure className={`f_${title} ${className}`}>
					<label>{translate(title)}</label>
					<b>{value}</b>
				</figure>
			);
		};
		if (!this.state.data) {
			return <div className="generalInfo"></div>;
		}
		return (
			<div className="generalInfo">
				{renderItem("bet", `${this.state.data.bet_amount.toFixed(this.state.data.betConfig.afterDot)} ${this.state.data.currency}`)}
				{renderItem(
					"win_amount",
					`${this.state.data.win_amount.toFixed(this.state.data.betConfig.afterDot)} ${this.state.data.currency}`,
					parseFloat(this.state.data.win_amount) > 0 ? "green" : "red"
				)}
				{renderItem("ID", this.state.data.transaction_id)}
				{renderItem("client_seed", this.state.data.client_salt)}
				{this.state.data.server_salt
					? renderItem("server_seed", this.state.data.server_salt)
					: renderItem("server_sha_512", this.state.data.hash)}
				{renderItem("bets_made_with_pair", this.state.data.nonce)}
				{this.state.data && renderItem("time", getDayTimeFromTimestamp(this.state.data.time))}
				{this.state.data.server_salt &&
					renderItem(
						"",
						<button className="btnDefault" onClick={() => this.goToVerify(this.state.data)}>
							{translate("verify")}
						</button>
					)}
			</div>
		);
	};
	render() {
		if (!this.state.data) return null;
		const data = [
			{
				id: "all",
				c: (
					<div className="rollInfoContainer">
						<div className={`contentAll ${this.state.loading ? "loading" : "ready"}`}>
							{this.state.loading && (
								<div onMouseDown={(e) => e.stopPropagation()} className="loaderWrapper">
									<Loader />
								</div>
							)}
							{this.renderInfo()}
							{this.state.data && this.renderGeneralInfo()}
						</div>
					</div>
				),
			},
		];
		return <List ren={true} data={data} />;
	}
}

export default RollInfo;

import React from "react";
//providers:
import { translate } from "../../providers/lang";
import lz from "lz-string";
//components:
import Window from "../Window/Window";
import Input from "../../components/Input/Input";
import uuidv4 from "uuid/v4";
//icons:
import Icon from "react-icons-kit";
import { repeat } from "react-icons-kit/fa/repeat";
//import { close } from "react-icons-kit/fa/close";
//redux:
import { connect } from "react-redux";
import { emit, getSocket } from "../../providers/socket";

//getSocket().on("onRandomize", data => {    console.log(data);});
//getSocket().on("onRandomize", (data) => { console.log(data); });

class Fairness extends Window {
	constructor(props) {
		super(props);
		this.setTitle(translate("fairness"));
		this.setClassName("fairnessWindow");
		this.state = {
			active: false,
			loading: false,
			client_salt_field: uuidv4().substr(0, 8),
			client_salt: "",
			hash: "",
			nonce: 0,
			config: null,
			prev_client_salt: "",
			prev_server_salt: "",
			prev_nonce: null,
			prev_config: null,
		};
		this.stateKeys = {
			client_salt: true,
			hash: true,
			nonce: true,
			config: null,
			prev_client_salt: true,
			prev_server_salt: true,
			prev_nonce: true,
			prev_config: true,
		};
		getSocket().on("onRandomize", async (data) => {
			if (typeof data === "string") data = JSON.parse(lz.decompressFromBase64(data));
			await this.setData(data);
			if (this.onRandomize) this.onRandomize(data);
		});
		if (props.onInit) props.onInit(this);

		this.onRandomize = null;

		this.endConstructor();
	}

	setOnRandomize = (fnc) => {
		this.onRandomize = fnc;
		return this;
	};

	onInputChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	onRenewClick = (e) => {
		//this.render();
		e.stopPropagation();
		this.setState({ client_salt_field: uuidv4().substr(0, 8) });
	};

	onRandomizeClick = (e, client_salt, server_salt) => {
		e.stopPropagation();
		emit("onRandomize", {
			client_salt: this.state.client_salt_field,
			randomize_client_salt: client_salt,
			randomize_server_salt: server_salt,
		});
		if (client_salt) this.setState({ client_salt_field: uuidv4().substr(0, 8) });
	};

	setActive = (active) => {
		this.setState({ active });
	};

	async setData(data) {
		const obj = {};
		for (let el of arguments) for (let k in this.stateKeys) if (k in el) obj[k] = el[k];
		await this.setState(obj);
	}

	renderContent() {
		const mob = this.props.parent.isMobileOrTablet;
		const txtCs = `${translate("new")} ${translate("client_seed").toLowerCase()}`;
		// const txtSs512 = `${translate("new")} ${translate("server_sha_512").toLowerCase()}`;

		return (
			<div className={`fairness w100 flex_v`}>
				{!mob && <label className="labelCs m5_b">{txtCs}</label>}
				<div className="inputCsWrapper">
					<div className="divCs relative m5_b">
						<Input
							disabled={!this.state.active}
							validation="salt"
							className="c"
							name="client_salt_field"
							maxLength={24}
							value={this.state.client_salt_field}
							placeholder={txtCs}
							onChange={this.onInputChange}
							onBlur={this.onInputChange}
						/>
						<button disabled={!this.state.active} className="btnIcon btnRenew" onClick={(e) => this.onRenewClick(e)}>
							<Icon icon={repeat} />
						</button>
					</div>
					<button
						disabled={this.state.loading || !this.state.active}
						className="btnRandomize btnDefault"
						onClick={(e) => this.onRandomizeClick(e, true, true)}
					>
						{translate("randomize")}
					</button>
				</div>
				<div className="flex_h flex_c_c w100 m5_t">
					<button
						className="btnDefault w100 break_word"
						disabled={this.state.loading || !this.state.active}
						onClick={(e) => this.onRandomizeClick(e, true, false)}
					>
						{translate("randomize")} {translate("client_seed").toLowerCase()} {translate("only").toLowerCase()}
					</button>
					<button
						className="btnDefault m10_l w100 break_word"
						disabled={this.state.loading || !this.state.active}
						onClick={(e) => this.onRandomizeClick(e, false, true)}
					>
						{translate("randomize")} {translate("server_seed").toLowerCase()} {translate("only").toLowerCase()}
					</button>
				</div>
				<div className="paneWrapper w100">
					<div className="pane">
						<h5>{translate("current_seed_pair")}</h5>
						<label>{translate("client_seed")}</label>
						<div className="dat">{this.state.client_salt}</div>
						<label>{translate("server_sha_512")}</label>
						<div className={`dat ${mob ? "f9" : ""}`}>{this.state.hash}</div>
						{this.state.nonce !== undefined && this.state.nonce !== null && (
							<div className="flex_h flex_c_0">
								<label>{translate("bets_made_with_pair")}</label>
								<div className="dat m10_l">{this.state.nonce}</div>
							</div>
						)}
						{this.state.config !== undefined && this.state.config !== null && (
							<div>
								<label>{translate("game_data")}</label>
								<div className="dat">{this.state.config}</div>
							</div>
						)}
					</div>
					<div className="pane">
						<h5>{translate("previous_seed_pair")}</h5>
						<label>{translate("client_seed")}</label>
						<div className="dat">{this.state.prev_client_salt ? this.state.prev_client_salt : "N/A"}</div>
						<label>{translate("server_seed")}</label>
						<div className="dat">{this.state.prev_server_salt ? this.state.prev_server_salt : "N/A"}</div>
						{this.state.prev_nonce !== undefined && this.state.prev_nonce !== null && (
							<div className="flex_h flex_c_0">
								<label>{translate("bets_made_with_pair")}</label>
								<div className="dat m10_l">{this.state.prev_nonce}</div>
							</div>
						)}
						{this.state.prev_config !== undefined && this.state.prev_config !== null && (
							<div>
								<label>{translate("previous_game_data")}</label>
								<div className="dat">{this.state.prev_config}</div>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

const a = (store, props) => {
	return {
		loadData: store.loadData,
	};
};
export default connect(a)(Fairness);

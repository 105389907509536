import { keywords } from "./keywords";
import "./keywords2";

const getLocale = (lng) => {
	//return "ru";
	lng = lng || (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;
	return keywords[lng] || "en";
};

export const translate = (keyword) => {
	//return keywords["ru"][keyword] || "--" + keyword;
	let loc = getLocale();
	//if (!keywords[loc]) return "--";
	if (!keywords[loc]) loc = "en";
	return keywords[loc][keyword] || keyword;
};

import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { Router } from "react-router-dom";
import { history } from "./providers/history";

//REDUX:
import { createStore } from "redux";
import { Provider } from "react-redux";

import mainStoreF from "./redux"; // { action }
export const store = createStore(mainStoreF);
//export const storeSet = async o => await store.dispatch(action({ payload: o }));
export const storeSet = async o => await store.dispatch({ type: "", payload: o });
//---2
ReactDOM.render(
	<Provider store={store}>
		<Router history={history}>
			<App />
		</Router>
	</Provider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from "react";
//components:
import Loader from "../../components/Loader/Loader";
//icons:
import Icon from "react-icons-kit";
import { check } from "react-icons-kit/fa/check";
import { close } from "react-icons-kit/fa/close";

class Window extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.renderOnlyVisible = false;
		this.className = "";
		this.title = "";
		this.closeIcon = "close";
		this.container = null;
		this.drag = {
			isFirstShow: true,
			active: false,
			mouseX: 0,
			mouseY: 0,
			startX: 0,
			startY: 0,
			partX: 0.5,
			partY: 0.5
		};
		this.endConstructor();
		window.addEventListener("resize", e => this.onWindowResize(e));
	}

	endConstructor() {
		let draggable = this.state.draggable && (!this.props.parent.isMobile || this.state.draggableOnMobile);
		Object.assign(this.state, { visible: false, loading: false, draggable });
		if (this.props.onInit) this.props.onInit(this);
	}

	setapContainer(element) {
		this.container = element;
	}

	setTitle(title) {
		this.title = title;
		return this;
	}

	setClassName(className) {
		this.className = className;
		return this;
	}

	setCloseIcon(iconName) {
		this.closeIcon = iconName;
	}

	setRenderOnlyVisible(renderOnlyVisible) {
		this.endConstructor = renderOnlyVisible;
		return this;
	}

	async show() {
		await this.setState({ visible: true }, () => this.state.draggable && this.onWindowResize());
		return this;
	}

	hide(e) {
		if (e) e.stopPropagation();
		this.setState({ visible: false }, () => document.activeElement.blur());
		return this;
	}

	loaderShow() {
		this.setState({ loading: true });
		return this;
	}

	loaderHide() {
		this.setState({ loading: false });
		return this;
	}

	renderContent() {
		return <div>Window Content</div>;
	}

	//------------------------------------------------------------------------------------------------------------------
	//DRAG
	//------------------------------------------------------------------------------------------------------------------

	onWindowResize(e) {
		const windowSize = this.props.parent.windowSize;
		const width = this.container.clientWidth;
		const height = this.container.clientHeight;
		const dw = windowSize.width - width;
		const dh = windowSize.height - height;
		this.windowSetPosition(dw * this.drag.partX, dh * this.drag.partY);
	}

	windowSetPosition(x, y) {
		const windowSize = this.props.parent.windowSize;
		const width = this.container.clientWidth;
		const height = this.container.clientHeight;
		const dw = windowSize.width - width;
		const dh = windowSize.height - height;

		if (arguments.length === 0) {
			if (this.container.style.left === "") x = 0;
			else x = parseFloat(this.container.style.left);
			if (this.container.style.top === "") y = 0;
			else y = parseFloat(this.container.style.top);
		}

		x = Math.max(Math.min(x, dw), 0);
		y = Math.max(Math.min(y, dh), 0);

		if (dw === 0) this.drag.partX = 0;
		this.drag.partX = x / dw;
		if (dh === 0) this.drag.partY = 0;
		this.drag.partY = y / dh;

		this.container.style.left = x + "px";
		this.container.style.top = y + "px";
	}

	async setDraggable(draggable = true) {
		if (draggable && this.props.parent.isMobile && !this.state.draggableOnMobile) return;
		await this.setState({ draggable });
		console.log("draggable", draggable);
	}

	containerMouseDown(e) {
		e.stopPropagation();
		if (!this.state.draggable) return;
		if (e.type === "touchstart") {
			this.drag.mouseX = e.touches[0].clientX;
			this.drag.mouseY = e.touches[0].clientY;
		} else {
			this.drag.mouseX = e.clientX;
			this.drag.mouseY = e.clientY;
		}

		if (this.container.style.left === "") this.drag.startX = 0;
		else this.drag.startX = parseFloat(this.container.style.left);
		if (this.container.style.top === "") this.drag.startY = 0;
		else this.drag.startY = parseFloat(this.container.style.top);

		this.drag.active = true;
		window.draggableObject = this;
	}

	render() {
		//if (!this.state.visible) return [];
		return (
			<div
				ref={element => this.setapContainer(element)}
				onMouseDown={e => !this.state.draggable && this.hide(e)}
				className={`${this.state.draggable ? "draggable " : ""}window ${this.state.visible ? "on" : "off"}`}
			>
				<div onMouseDown={e => e.stopPropagation()} className={`loaderWrapper ${this.state.loading ? "on" : "off"}`}>
					<Loader />
				</div>

				<div
					onMouseDown={e => this.containerMouseDown(e)}
					onTouchStart={e => this.containerMouseDown(e)}
					className={`${this.state.draggable ? "draggable " : ""} content ${this.className} ${this.state.visible ? "on" : "off"}`}
				>
					<div className="windowHeader">
						<label className="windowTitle">{this.title}</label>
						<button onClick={e => this.hide(e)} className="btnIcon btnClose">
							<Icon icon={Window.icons[this.closeIcon]} size={18} />
						</button>
					</div>
					{this.renderContent()}
				</div>
			</div>
		);
	}
}

function windowMouseMove(e) {
	if (Date.now() - window.lastDragTime < 20) return;
	const obj = window.draggableObject;
	if (!obj) return;

	let x = 0;
	let y = 0;

	if (e.type === "touchmove") {
		x = obj.drag.startX + e.touches[0].clientX - obj.drag.mouseX;
		y = obj.drag.startY + e.touches[0].clientY - obj.drag.mouseY;
	} else {
		x = obj.drag.startX + e.clientX - obj.drag.mouseX;
		y = obj.drag.startY + e.clientY - obj.drag.mouseY;
	}

	obj.windowSetPosition(x, y);

	// obj.container.style.left = x + "px";
	// obj.container.style.top = y + "px";
	window.lastDragTime = Date.now();
}

function windowMouseUp(e) {
	if (!window.draggableObject) return;
	window.draggableObject.drag.active = false;
	window.draggableObject = null;
}

window.addEventListener("mousemove", e => windowMouseMove(e), { passive: false });
window.addEventListener("mouseup", e => windowMouseUp(e), { passive: false });

window.addEventListener("touchmove", e => windowMouseMove(e), { passive: false });
window.addEventListener("touchup", e => windowMouseUp(e), { passive: false });
window.addEventListener("touchcancel", e => windowMouseUp(e), { passive: false });

Window.icons = {
	check,
	close
};

export default Window;

import io from "socket.io-client";
import * as socketHandlers from "../classes/socketHandlers";
import Reconnect from "../containers/Reconnect/Reconnect";
import lz from "lz-string";
//import { xCode, getNonce } from "../classes/socketHandlers";
//import { sortObject } from "../utills";
//import crypto from "crypto";

// const hmacSha1 = (secret, str) => {
// 	return crypto
// 		.createHmac("sha1", secret)
// 		.update(str)
// 		.digest("hex");
// };

export const emit = async (eventName, data, callback) => {
	data["ev"] = eventName;
	socket && socket.emit("onE", lz.compressToBase64(JSON.stringify(data)), callback);
	//socket && socket.emit("onE", data, callback);
	return;

	// const xNonce = getNonce();
	// data["x-nonce"] = xNonce;
	// data["x-code"] = xCode;
	// data["ev"] = eventName;
	// const o = sortObject(data);
	// o["x-sign"] = hmacSha1(`${xCode}-${xNonce}`, JSON.stringify(o));
	// socket && socket.emit("onE", o, callback);
};

// export const emit = (eventName, o, callback) => {
//     socket && socket.emit(eventName, o, callback);
// };
const socketSystemEvents = {
	CONNECT: "connect",
	DISCONNECT: "disconnect",
	CONNECT_ERROR: "connect_error",
	RECONNECT: "reconnect_attempt",
	RECONNECT_FAILED: "reconnect_failed",
	RECONNECT_ERROR: "reconnect_error",
	RECONNECTING: "reconnecting",
	FORCE_ERROR: "FORCE_ERROR",
	ERROR_TOAST: "ERROR_TOAST",
};
export const websocketsSupported = () => {
	return "WebSocket" in window || "MozWebSocket" in window;
};
export const socketEvents = ["onTest", "t"];
let socket = null;

export const resetSocket = () => {
	socket.disconnect();
	socket = null;
};

export const getSocket = (token, url) => {
	if (socket !== null) return socket;
	if (token === null) return null;
	if (!websocketsSupported()) {
		console.error("websockets not supported!");
		return null;
	}
	const path = `${url}?token=${token}`;
	if (!process.env || process.env.NODE_ENV !== "production") console.log(path);
	socket = io(path, { reconnect: false, transports: ["websocket"] }); //, "polling"
	!!socket &&
		socket.on(socketSystemEvents.CONNECT, () => {
			socket.io.opts.transports = ["websocket"]; //, "polling"
			socketEvents.forEach((el) => {
				!socket.hasListeners(el) && socket.on(el, (o) => socketHandlers[el](o));
			});
			//storeSet({ storeKey: "socketConnected", data: true });
			if (!process.env || process.env.NODE_ENV !== "production") console.log(`CONNECTED ${socket.connected}`);
			Reconnect.main.hide();
		});
	socket.on(socketSystemEvents.RECONNECT, () => {
		if (!process.env || process.env.NODE_ENV !== "production") {
			console.log(`RECONNECT ${socket.connected}`);
			setTimeout(() => console.log(`${socket.connected}`), 100);
			Reconnect.main.hide();
		}
	});
	socket.on(socketSystemEvents.RECONNECTING, (attemptNumber) => {
		if (!process.env || process.env.NODE_ENV !== "production") console.log(`RECONNECTING (${attemptNumber})...${socket.connected}`);
	});
	socket.on(socketSystemEvents.RECONNECT_FAILED, () => {
		if (!process.env || process.env.NODE_ENV !== "production") console.log(`RECONNECT_FAILED ${socket.connected}`);
	});
	socket.on(socketSystemEvents.RECONNECT_ERROR, (err) => {
		//storeSet({ storeKey: "socketConnected", data: false });
		if (!process.env || process.env.NODE_ENV !== "production") console.log("RECONNECT_ERROR: ", err.message);
	});
	socket.on(socketSystemEvents.CONNECT_ERROR, (err) => {
		if (!process.env || process.env.NODE_ENV !== "production") console.log("CONNECT_ERROR: ", err.message);
	});
	socket.on(socketSystemEvents.DISCONNECT, () => {
		//TODO OFFLINE NOTIFICATION
		// setInterval(() => {
		//     if (socket && !socket.connected) {
		//         storeSet({ storeKey: "socketConnected", data: false });
		//     }
		// }, showOfflineNotificationAfterSeconds * 1000);
		Reconnect.main.show();
		if (!process.env || process.env.NODE_ENV !== "production") console.log("DISCONNECTED, Unsubscribing from listeners...");
		socket && socket.off(socketSystemEvents.CONNECT); //remove listeners
	});
	return socket;
};

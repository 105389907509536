import { stripTrailingSlashes } from "../utills/index";
// const url = window.location.port + "" === "3005" ? `http://${window.location.hostname}:5555` : "https://*.pandoragames.io";

export const isProd = () => window.location.port + "" !== "3005";
const urlFront = stripTrailingSlashes(isProd() ? "https://*.pandoragames.io" : `http://${window.location.hostname}:3005`);
const urlBack = stripTrailingSlashes(isProd() ? "https://*.pandoragames.io" : `http://${window.location.hostname}:5555`);

export const config = {
	http: { urlBack, urlFront },
	apiUrlBack: null,
	apiUrlFront: null,
	// apiHttpUrl: null, //will be placed automatically
	merchantId: null,
};

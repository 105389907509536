import React from "react";
import Window from "../Window/Window";
import { translate } from "../../providers/lang";

class Reconnect extends Window {
	static main = null;

	constructor(props) {
		super(props);
		Reconnect.main = this;
		this.setClassName("reconnectWindow");
		super.endConstructor();
	}

	show(e) {
		this.props.parent.loaded.socket = false;
		if (this.props.parent.gameSocket && !this.props.parent.gameSocket.socket.connected) super.show(e);
	}

	hide(e) {
		if (this.props.parent.gameSocket && this.props.parent.gameSocket.socket.connected) super.hide(e);
	}

	onReconnect(e) {
		if (e) e.stopPropagation();
		this.props.parent.gameSocket.socket.connect();
		if (this.props.parent.gameSocket.socket.connected) {
			this.props.parent.gameSocket.emit("onLoad", {});
			this.hide();
		} else setTimeout(() => this.onReconnect(), 100);
	}

	renderContent() {
		return (
			<button onClick={e => this.onReconnect(e)} className="btnDefault btnReconnect p10_all m5_t">
				{translate("reconnect")}
			</button>
		);
	}
}

export default Reconnect;

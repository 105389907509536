import React from "react";
export const delay = (ms, response = undefined) => new Promise((resolve) => setTimeout(() => resolve(response), ms));

export const stripTrailingSlashes = (url) => url.trim().replace(/^(.+?)\/*?$/, "$1");
export const getDayFromTimestamp = (unix_timestamp) => {
	const d = new Date(unix_timestamp);
	return d.getFullYear() + "-" + (d.getMonth() + 1 < 10 ? "0" : "") + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" : "") + d.getDate();
};
const prependZero = (n) => (n * 1 < 10 ? `0${n}` : `${n}`);
export const getTimeFromTimestamp = (unix_timestamp, options) => {
	const date = new Date(unix_timestamp);
	const d = prependZero(date.getDate());
	const h = prependZero(date.getHours());
	const m = prependZero(date.getMinutes());
	const s = `00${date.getSeconds()}`.slice(-2);
	if (options && options.str) {
		return `${d},${h}:${m}${options && options.s ? `:${s}` : ""}`;
	}
	return (
		<>
			<u>{d}</u>,<i>{`${h}:${m}${options && options.s ? `:${s}` : ""}`}</i>
		</>
	);
};

export const getDayTimeFromTimestamp = (unix_timestamp, textMode = false) => {
	const d = new Date(unix_timestamp);
	const day = `${d.getDate() < 10 ? "0" : ""}${d.getDate()}`;
	const time = getTimeFromTimestamp(unix_timestamp, textMode ? { str: true } : null);
	if (textMode) return `${day}, ${time}`;
	return (
		<>
			<b className="d">{day}</b>
			<b className="t">{time}</b>
		</>
	);
};

export const hasParent = () => window.top !== window.self;

export const isInt = (n) => parseInt(n) === n;
export const isFloat = (f) => parseFloat(f) === f;
export const isString = (str) => typeof str === "string";

/**Time limited promise all, you can pass 1 promise or many */
export const promiseAll = (promises, timeLimit, timeLimitResponse) => {
	return Promise.race([delay(timeLimit, timeLimitResponse), Promise.all(Array.isArray(promises) ? promises : [promises])]);
};
export const randomBetween = (min, max) => {
	return Math.floor(Math.random() * max) + min;
};

export const sortObject = (obj) => {
	return Object.keys(obj)
		.sort()
		.reduce((a, v) => {
			a[v] = obj[v];
			return a;
		}, {});
};

var timeLogLastTime;
export const timeLog = (restart = false) => {
	if (restart || !timeLogLastTime) {
		timeLogLastTime = Date.now();
	} else {
		console.log(`Timer: ${Date.now() - timeLogLastTime}`.cyan);
		timeLogLastTime = Date.now();
	}
};

/**
 * converts object to array for easy storing to the redis.
 * ignores properties with null values, example {a:1, b:2} = > [a,1,b,2]
 * @param {*} o
 */
export const toRedisArr = (o) => {
	const arr = [];
	Object.keys(o).forEach((key) => {
		if (o[key] !== null) arr.push(key, o[key]);
	});
	return arr;
};
export const trimAfterDot = (v, num = 8) => {
	v += "";
	if (v.indexOf(".") === -1) return v;
	const o = v.split(".");
	return o[0] + "." + o[1].substr(0, num);
};
/*
console.log(tryParseFloat(NaN, -1)) //returns -1
console.log(tryParseFloat(null, -1)) //returns -1
console.log(tryParseFloat(undefined, -1)) //returns -1
console.log(tryParseFloat(true, -1)) //returns -1
console.log(tryParseFloat(false, -1)) //returns -1
console.log(tryParseFloat(0.0, -1)) //returns 0
console.log(tryParseFloat(0, -1)) //returns 0
console.log(tryParseFloat({}, -1)) //returns -1
console.log(tryParseFloat([], -1)) //returns -1
console.log(tryParseFloat(-7, -1)) //returns -7
console.log(tryParseFloat(5e-7, -1)) //returns 5e-7
console.log(tryParseFloat('', -1)) //returns -1
console.log(tryParseFloat('abc7', -1)) //returns -1
console.log(tryParseFloat('77abc7', -1)) //returns 77
*/
export const tryParseFloat = (v, defaultValue) => {
	if (isFloat(v)) return v;
	else if (isString(v) && v.length) {
		const a = parseFloat(v);
		return isNaN(a) ? defaultValue : a;
	}
	return defaultValue;
};

// module.exports = {
// 	delay,
// 	getDayFromTimestamp,
// 	getTimeFromTimestamp,
// 	isInt,
// 	isFloat,
// 	isString,
// 	promiseAll,
// 	randomBetween,
// 	sortObject,
// 	tryParseFloat,
// 	toRedisArr,
// 	timeLog
// };

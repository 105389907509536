import React, { Component } from "react";
import BScroll from "better-scroll";

class List extends Component {
	constructor(props) {
		super(props);
		/**
		 * data - [row - {id, c}]
		 * ren - boolean
		 */
		this.state = {
			tim: Date.now()
		};
		this.scrollRect = null;
		this.scrollEnd = null;
		this.data = this.props.data ? this.props.data : [];
		//generate fake data:
		if (!this.props.data) for (let i = 0; i < 100; i++) this.data.push({ id: i, c: <div>{`some text: ${i}`}</div> });
	}
	delay = ms => new Promise(res => setTimeout(res, ms));
	scrollToEnd = async (t = 300) => {
		//await this.delay(300);
		this.scroll.refresh();
		this.scroll.scrollToElement(this.scrollEnd, t);
	};
	scrollToTop = async (t = 300) => {
		//if(t) await this.delay(t);
		this.scroll.refresh();
		this.scroll.scrollTo(0, 0, t);
	};
	update = props => {
		if (typeof props !== "object" && props !== null) return;
		for (let key in props) this[key] = props[key];
		this.doRender();
	};
	doRender = () => {
		if (this.props.ren) {
			this.scroll.refresh();
			return;
		}
		this.setState({ tim: `${Date.now()}-${Math.random()}` }, () => this.scroll.refresh());
	};
	refresh = () => this.scroll.refresh();
	componentDidMount = () => {
		this.initScroll();
	};
	shouldComponentUpdate = (nextProps, nextState) => {
		return this.props.ren ? true : this.state.tim !== nextState.tim;
	};
	initScroll = () => {
		const options = {
			click: true,
			stopPropagation: true,
			scrollbar: {
				fade: false,
				interactive: true // new in 1.8.0
			},
			mouseWheel: {
				speed: 20,
				invert: false
			},
			// preventDefaultException: { className: /(^|\s)chatItemText(\s|$)/ }
			preventDefaultException: { tagName: /^(INPUT|TEXTAREA|BUTTON|SELECT)$/, className: /(^|\s)chatItemText(\s|$)/ }
		};
		this.scroll = new BScroll(this.scrollRect, options);
		this.props.onScroll && this.scroll.on("scroll", this.props.onScroll);
		// this.scrollToEnd();
	};
	componentWillUnmount() {
		this.props.onScroll && this.scroll.off("scroll", this.props.onScroll);
	}

	render() {
		const content =
			(this.props.ren ? this.props.data : this.data).length > 0 ? (
				(this.props.ren ? this.props.data : this.data).map((row, i) => <li key={row.id ? row.id : i}>{row.c}</li>)
			) : (
				<li className="h">---</li>
			);
		return (
			<div ref={el => (this.scrollRect = el)} className="listScroll">
				<ul>{content}</ul>
				{/* you can put some other DOMs here, it won't affect the scrolling */}
				<div ref={el => (this.scrollEnd = el)} />
			</div>
		);
	}
}

export default List;

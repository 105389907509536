import update from "immutability-helper";

const initialState = {
	loadData: {},
	betConfig: {},
	random: null
};

export const action = o => {
	if (!o.type) o.type = "";
	return o;
};

export default (state = initialState, action) => {
	if (action.type.charAt(0) === "@") return state;
	return update(state, action.payload);
};

import React from "react";

//components:
import Window from "../Window/Window";
import Input from "../../components/Input/Input";
import Toggler from "../../components/Toggler/Toggler";
import List from "../../components/List/List";
//import { getSocket } from "../../providers/socket";
//provider
import { translate } from "../../providers/lang";
//icons:
import Icon from "react-icons-kit";
import { ic_toll as iconPercent } from "react-icons-kit/md/ic_toll";
import { percent as iconFixed } from "react-icons-kit/fa/percent";
import { squareO as unchecked } from "react-icons-kit/fa/squareO";
import { checkSquareO as checked } from "react-icons-kit/fa/checkSquareO";
//redux
//import { connect } from "react-redux";
//other
import Decimal from "decimal.js";

export default class AutoPlay extends Window {
	constructor(props) {
		super(props);
		this.setTitle(translate("automated_betting"));
		this.setClassName("autoPlayWindow");
		this.state = {
			betConfig: {},
			active: false,
			loading: false,
			show_winsPanel: true,
			show_lossesPanel: true,
			showReverse: true,
			lossesCount: 1,
			winsCount: 1,
			lossesInc: 0,
			winsInc: 0,
			lossesBy: 0,
			winsBy: 0,
			lossesAmountType: 0,
			winsAmountType: 0,
			lossesReverse: 0,
			winsReverse: 0,
			lossesStop: 0,
			winsStop: 0,
			speed: 1,
			betsNumber: "0",
			balanceUnder: "",
			balanceOver: "",
			amountOver: "",
			isRolling: false,
			canStart: true,
			//togglers:
			lossesCounterToggler: 0,
			winsCounterToggler: 0,
			lossesToBaseToggler: 0,
			winsToBaseToggler: 0,
			reason: "",
		};
		if (props.onInit) props.onInit(this);

		this.speeds = [0, 1000, 500, 1];

		this.startTime = 0;
		this.statusTimer = null;

		//events
		this.onStart = null;
		this.onStartStop = null;
		this.onPlay = null;
		this.onReverse = null;
		this.onBetChange = null;
		this.onFinish = null;
		this.onTotalBetAmount = null;

		this.needReverse = false;
		this.setBaseBetAmountOnStop = false;

		this.betAmount = 0;
		this.deltaPart = 1;
		this.timerWaiting = false;
		this.resultWaiting = false;
		this.winsCount = 0;
		this.lossesCount = 0;
		//this.betsCount = 0;
		this.baseBetAmount = 0;

		this.endConstructor();
		this.listRef = React.createRef();
	}

	baseBetAmountOnStop(setBaseBetAmountOnStop = true) {
		this.setBaseBetAmountOnStop = setBaseBetAmountOnStop;
	}

	async timer() {
		if (!this.state.isRolling) return;
		this.timerWaiting = false;
		if (this.resultWaiting) return;
		this.resultWaiting = true;
		if (this.onPlay) if (this.needReverse && this.onReverse) await this.onReverse(this);
		if ((await this.onPlay(this.betAmount)) === false) {
			await this.stop("noBalance");
			return;
		}
		this.deltaPart = 1;
		this.timerWaiting = true;
		setTimeout(() => this.timer(), this.speeds[this.state.speed]);
	}

	toggleStart = (e) => {
		if (e) {
			e.stopPropagation();
			if (!this.state.isRolling) this.hide();
		}
		if (this.state.isRolling) {
			this.stop();
			return;
		}
		const betAmount = this.onTotalBetAmount();
		if (betAmount <= 0) return this.props.parent.notificationClear().notification(translate("noBetAmount"));
		this.winsCount = 0;
		this.lossesCount = 0;
		//this.betsCount = 0;
		this.deltaPart = 1;
		this.betAmount = betAmount;
		this.baseBetAmount = this.betAmount;
		this.timerWaiting = false;
		this.resultWaiting = false;
		this.setState({ isRolling: true, reason: "" }, () => {
			//let socket = getSocket();
			//if (!socket.hasListeners("onStopAuto")) socket.on("onStopAuto", () => this.onStopAuto_Socket());
			if (this.onStart) this.onStart(this);
			if (this.onStartStop) this.onStartStop(this);
			//this.props.parent.gameSocket.gameData.isAuto = this.state.isRolling;
			this.startTime = Date.now();
			this.timer();
		});
	};

	onStopAuto_Socket() {
		this.stop("", true);
	}

	//{win, balance}
	setResult = async (result) => {
		if (result.error) {
			this.resultWaiting = false;
			if (!this.timerWaiting) this.timer();
			return;
		}
		if (!this.state.isRolling) return;
		const key = result.win ? "wins" : "losses";
		const revKey = result.win ? "losses" : "wins";
		const f = (cap) => this.state[`${key}${cap}`];
		const r = (cap) => this.state[`${revKey}${cap}`];
		//this.betAmount = this.props.parent.betAmount.value;
		this.needReverse = false;

		const balance = new Decimal(result.balance);
		if (balance.lt(this.state.balanceUnder)) await this.stop("balanceUnder");
		if (new Decimal(this.state.balanceOver).gt(0) && balance.gt(this.state.balanceOver)) await this.stop("balanceOver");
		//this.betsCount++;

		let betsNumber = new Decimal(this.state.betsNumber).toNumber();
		if (betsNumber > 0) {
			betsNumber--;
			await this.setState({ betsNumber });
			if (betsNumber === 0) await this.stop("betsCount");
		}
		//if (new Decimal(this.state.betsNumber).gt(0) && new Decimal(this.state.betsNumber).lte(this.betsCount)) await this.stop("betsCount");
		this[`${key}Count`]++;
		if (r("CounterToggler") === 1) this[`${revKey}Count`] = 0; //rev streak zero
		if (this[`${key}Count`] >= parseInt(f("Count"))) {
			this[`${key}Count`] = 0;
			if (f("Stop") === 1) await this.stop(`${key}Stop`);
			else {
				if (f("Reverse") === 1) this.needReverse = true;
				if (f("ToBaseToggler") === 0) {
					this.deltaPart = new Decimal(this.baseBetAmount).div(this.betAmount);
					this.betAmount = new Decimal(this.baseBetAmount);
				} else {
					let amount;
					if (f("AmountType") === 0) amount = new Decimal(this.betAmount).mul(f("Inc")).div(100).add(this.betAmount);
					else amount = new Decimal(this.betAmount).add(f("Inc"));
					if (new Decimal(this.state.amountOver).gt(0) && amount.gt(this.state.amountOver)) await this.stop("amountOver");
					else if (amount.gt(this.props.parent.betAmount.max)) await this.stop("amountOverMax");
					else if (this.onBetChange) {
						let delta = amount.div(this.betAmount);
						this.deltaPart = delta.toNumber();
						this.onBetChange(amount, delta, this.baseBetAmount);
					}
					this.betAmount = amount.toNumber();
				}
			}
		}

		if (new Decimal(this.betAmount).lte(0)) await this.stop("noBetAmount");
		else if (new Decimal(this.betAmount).gt(result.balance)) await this.stop("noBalance");
		this.resultWaiting = false;
		if (!this.timerWaiting) this.timer();
	};

	setUseStatus = async () => {
		// if (this.state.isRolling && Date.now() - this.startTime > 1500) return await this.stop("anotherAuto");
		// if (this.statusTimer) clearTimeout(this.statusTimer);
		// if (this.onStart) this.onStart(this);
		// if (this.onStartStop) this.onStartStop(this);
		// //if (this.state.canStart) await this.setState({ canStart: false });
		// this.statusTimer = setTimeout(() => {
		// 	this.statusTimer = null;
		// 	if (this.onFinish) this.onFinish();
		// 	if (this.onStartStop) this.onStartStop(this);
		// 	//this.setState({ canStart: true });
		// }, 1500);
	};

	setBetConfig = async (betConfig) => {
		const stateUpdate = { betConfig };
		if (this.state.balanceUnder === "") {
			const amount = `0.${"0".repeat(betConfig.afterDot)}`;
			stateUpdate.balanceUnder = amount;
			stateUpdate.balanceOver = amount;
			stateUpdate.amountOver = amount;
		}
		await this.setState(stateUpdate);
	};

	async stop(reason = "") {
		await this.setState({ isRolling: false, reason });
		//this.props.parent.gameSocket.gameData.isAuto = this.state.isRolling;
		this.props.parent.notificationClear();
		if (reason !== "") this.props.parent.notification(translate(reason));
		if (this.onFinish) this.onFinish();
		if (this.onStartStop) this.onStartStop(this);
		if (this.statusTimer) clearTimeout(this.statusTimer);
		if (this.setBaseBetAmountOnStop) this.props.parent.betAmount.set(this.baseBetAmount);
	}

	showReverse = async (show = true) => {
		await this.setState({ showReverse: show });
		return this;
	};

	showWinsLosesPanels = async (showWinsPanel, showLosesPanel = false) => {
		await this.setState({ show_winsPanel: showWinsPanel, show_lossesPanel: showLosesPanel });
		return this;
	};

	componentDidUpdate = (prevProps) => {
		// const afterDot = this.state.betConfig.afterDot !== prevProps.betConfig.afterDot;
		// if (afterDot && this.state.balanceUnder === "") {
		// 	const amount = `0.${"0".repeat(this.props.betConfig.afterDot)}`;
		// 	this.setState({ balanceUnder: amount, balanceOver: amount, amountOver: amount });
		// }
	};

	onInputChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	toggle = (e, section, stateKey) => {
		e.stopPropagation();
		this.setState({ [`${section}${stateKey}`]: 1 - this.state[`${section}${stateKey}`] });
	};
	toggleSpeed = (e, speed) => {
		e.stopPropagation();
		if (this.state.speed === speed) return;
		this.setState({ speed });
	};
	togglerToggle = async (id, value) => this.setState({ [id]: 1 - this.state[id] });
	getDisabledState = () => this.state.isRolling;
	isRolling = () => this.state.isRolling;
	isAuto = () => this.state.isRolling;
	getTimeInterval = () => this.speeds[this.state.speed] / 1000;

	renderWinsLosses = (section) => {
		if (!this.state[`show_${section}Panel`]) return null;
		const amountType = this.state[`${section}AmountType`];
		return (
			<section className={`${section} m10_b`}>
				<label className="m10_b">{translate(section)}</label>
				<div className="flex_h relative m10_b">
					<Toggler
						id={`${section}CounterToggler`}
						onToggle={this.togglerToggle}
						disabled={this.getDisabledState()}
						value={this.state[`${section}CounterToggler`]}
						captions={[translate("every"), translate("streak")]}
					/>
					<Input
						// disabled={!this.state.active}
						validation="integer"
						min={1}
						max={99999}
						disabled={this.getDisabledState()}
						className="count"
						name={`${section}Count`}
						maxLength={5}
						onChange={this.onInputChange}
						onBlur={this.onInputChange}
						value={this.state[`${section}Count`]}
					/>
				</div>
				<div className={`flex_h relative m10_b ${this.state[`${section}Stop`] ? "hide" : ""}`}>
					<Toggler
						onToggle={this.togglerToggle}
						id={`${section}ToBaseToggler`}
						value={this.state[`${section}ToBaseToggler`]}
						disabled={this.getDisabledState()}
						captions={[translate("to_base"), translate(this.state[`${section}Inc`] * 1 >= 0 ? "increase_by" : "decrease_by")]}
					/>
					<Input
						// disabled={!this.state.active}
						validation={amountType === 0 ? `percent` : `amount`}
						min={amountType === 0 ? -100 : -999999999}
						max={amountType === 0 ? 99999 : 999999999}
						disabled={this.getDisabledState() || this.state[`${section}ToBaseToggler`] === 0}
						className="inc"
						name={`${section}Inc`}
						toFixed={amountType === 0 ? 2 : this.state.betConfig.afterDot}
						onChange={this.onInputChange}
						onBlur={this.onInputChange}
						value={this.state[`${section}Inc`]}
					/>
					<button
						onClick={(e) => [this.setState({ [`${section}Inc`]: 0 }), this.toggle(e, section, "AmountType")]}
						disabled={this.getDisabledState() || this.state[`${section}ToBaseToggler`] === 0}
						className={`btnIcon amountType ${section}AmountType`}
					>
						<Icon icon={this.state[`${section}AmountType`] ? iconPercent : iconFixed} />
					</button>
				</div>
				<div className="flex_h relative">
					<div className={`flex_h flex1 flex_c_0 ${this.state.showReverse ? "" : "remove"}`}>
						<button className="btnIcon m10_r" onClick={(e) => this.toggle(e, section, "Reverse")} disabled={this.getDisabledState()}>
							<Icon icon={this.state[`${section}Reverse`] ? checked : unchecked} />
						</button>
						<label>{translate("reverse_bet")}</label>
					</div>
					<div className="flex_h flex1 flex_c_0">
						<button className="btnIcon m10_r" onClick={(e) => this.toggle(e, section, "Stop")} disabled={this.getDisabledState()}>
							<Icon icon={this.state[`${section}Stop`] ? checked : unchecked} />
						</button>
						<label>{translate("stop_auto")}</label>
					</div>
				</div>
			</section>
		);
	};
	renderSpeed = () => {
		return (
			<div className="speed flex_v m10_b">
				<label className="m10_b">{translate("speed")}</label>
				<div className="flex_h flex1 relative">
					<button
						onClick={(e) => this.toggleSpeed(e, 1)}
						className={`btnDefault flex1 ${this.state.speed === 1 ? "on" : "off"}`}
						//disabled={this.getDisabledState()}
					>
						1x
					</button>
					<button
						onClick={(e) => this.toggleSpeed(e, 2)}
						className={`btnDefault flex1 m10_l  ${this.state.speed === 2 ? "on" : "off"}`}
						//disabled={this.getDisabledState()}
					>
						2x
					</button>
					<button
						onClick={(e) => this.toggleSpeed(e, 3)}
						className={`btnDefault flex1 m10_l  ${this.state.speed === 3 ? "on" : "off"}`}
						//disabled={this.getDisabledState()}
					>
						3x
					</button>
				</div>
			</div>
		);
	};
	renderLimits = () => {
		return (
			<section className="limits flex_v">
				<label className="m10_b">{translate("limits")}</label>
				<div className="item flex_h flex_c_b">
					<label>{translate("limit_number_of_bets_by")}:</label>
					<Input
						// disabled={!this.state.active}
						validation={`integer`}
						min={0}
						max={999999999}
						disabled={this.getDisabledState()}
						name={`betsNumber`}
						onChange={this.onInputChange}
						onBlur={this.onInputChange}
						value={this.state[`betsNumber`]}
					/>
				</div>
				<div className="item flex_h flex_c_b">
					<label>{translate("stop_if_balance_under")}:</label>
					<Input
						// disabled={!this.state.active}
						validation={`amount`}
						min={0}
						max={999999999}
						disabled={this.getDisabledState()}
						toFixed={this.state.betConfig.afterDot}
						name={`balanceUnder`}
						onChange={this.onInputChange}
						onBlur={this.onInputChange}
						value={this.state[`balanceUnder`]}
					/>
				</div>
				<div className="item flex_h flex_c_b">
					<label>{translate("stop_if_balance_over")}:</label>
					<Input
						// disabled={!this.state.active}
						validation={`amount`}
						min={0}
						max={999999999}
						disabled={this.getDisabledState()}
						toFixed={this.state.betConfig.afterDot}
						name={`balanceOver`}
						onChange={this.onInputChange}
						onBlur={this.onInputChange}
						value={this.state[`balanceOver`]}
					/>
				</div>
				{this.state.show_winsPanel || this.state.show_lossesPanel ? (
					<div className="item flex_h flex_c_b">
						<label>{translate("stop_if_bet_amount_over")}:</label>
						<Input
							// disabled={!this.state.active}
							validation={`amount`}
							min={0}
							max={999999999}
							disabled={this.getDisabledState()}
							toFixed={this.state.betConfig.afterDot}
							name={`amountOver`}
							onChange={this.onInputChange}
							onBlur={this.onInputChange}
							value={this.state[`amountOver`]}
						/>
					</div>
				) : null}
			</section>
		);
	};
	renderContent() {
		if (!this.state.betConfig.afterDot) return null;
		const el = (
			<div className="autoPlay">
				<div className="sectionWrapper">
					{this.renderWinsLosses("losses")}
					{this.renderWinsLosses("wins")}
				</div>
				{this.renderSpeed()}
				<div className="sectionWrapper">{this.renderLimits()}</div>
				<section className="autoRoll flex_h flex_c_c">
					<button
						className="btnDefault btnAutoRoll"
						onClick={(e) => this.toggleStart(e)}
						disabled={!this.state.isRolling && !this.state.canStart}
					>
						{translate(this.state.isRolling ? "stop" : "auto_roll")}
					</button>
				</section>
			</div>
		);
		return <div className="list">{<List ref={this.listRef} ren={true} data={[{ id: 1, c: el }]} />}</div>;
	}
}

// const a = (store, props) => {
// 	return {
// 		betConfig: store.betConfig
// 	};
// };
// export default connect(a)(AutoPlay);

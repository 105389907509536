import { emit, getSocket } from "../providers/socket";
//import { xCodeGet } from "./socketHandlers";
//import { storeSet } from "../";
import { translate } from "../providers/lang";
import lz from "lz-string";
import { config } from "../config";

export class GameSocket {
	constructor(module) {
		this.module = module;
		this.canvas = module;
		this.socket = null;

		this.activeTimer = null;

		this.gameData = {
			active: false,
			isAuto: false,
			playCount: 0,
			steps: [],
			results: [],
			choice: [],
			random: [],
			randomHistory: [],
			started: false,
			config: 0,
			stats: null,
			serverConfig: null, //{ bet:{ min, max, afterDot, hrAmount } houseEdge }
			result: 0, // -1 : lose;     0:nothing;      1:win;
			lose: false,
			win: false,
			lastWinAmount: 0,
			lastBetAmount: 0,
			amountInWay: null, // { amountIn, amountOut, delta, active }
			transaction_id: "",
			getLastStep: function () {
				if (!this.steps || this.steps.length === 0) return null;
				return this.steps[this.steps.length - 1];
			},
			getLastStepResult: function () {
				if (!this.results || this.results.length === 0) return null;
				return this.results[this.results.length - 1];
			},
		};

		this.onLoad = this.onLoad.bind(this);
		this.onBet = this.onBet.bind(this);
		this.onAction = this.onAction.bind(this);
		this.onWin = this.onWin.bind(this);
		this.onGameDisabled = this.onGameDisabled.bind(this);
		this.onBreakDone = this.onBreakDone.bind(this);
		this.onError = this.onError.bind(this);
		this.onLoadEvent = null;
		this.onBetEvent = null;
		this.onActionEvent = null;
		this.onWinEvent = null;
		this.onBreakDoneEvent = null;
		this.onErrorEvent = null;
		this.onGameDataEvent = null;
	}

	init() {
		if (this.socket) return;
		this.socket = getSocket();
		this.socket.on("onLoad", this.onLoad);
		this.socket.on("onBet", this.onBet);
		this.socket.on("onAction", this.onAction);
		this.socket.on("onWin", this.onWin);
		this.socket.on("onGameDisabled", this.onGameDisabled);
		this.socket.on("onBreakDone", this.onBreakDone);
		this.socket.on("onError", this.onError);
		emit("onLoad", {});
	}

	emit(name, data) {
		emit(name, data);
		return this;
	}

	emitBet(data) {
		this.gameData.active = false;
		//data.auto = this.module.autoPlay.state.isRolling;
		emit("onBet", data);
		this.activeTimer = setTimeout(() => {
			this.gameData.active = true;
		}, 1000);
		return this;
	}

	emitAction(data) {
		this.gameData.active = false;
		emit("onAction", data);
		this.activeTimer = setTimeout(() => {
			this.gameData.active = true;
		}, 1000);
		return this;
	}

	emitEnd(data) {
		this.gameData.active = false;
		if (!data) data = {};
		emit("onEnd", data);
		this.activeTimer = setTimeout(() => {
			this.gameData.active = true;
		}, 1000);
		return this;
	}

	addGameDataParameter(name, startValue = null) {
		this.gameData[name] = startValue;
		return this;
	}

	setGameData(data) {
		for (let k in this.gameData) if (typeof data[k] !== "undefined") this.gameData[k] = data[k];
	}

	setOnLoad(onLoad) {
		this.onLoadEvent = onLoad;
		return this;
	}

	setOnBet(onBet) {
		this.onBetEvent = onBet;
		return this;
	}

	setOnAction(onAction) {
		this.onActionEvent = onAction;
		return this;
	}

	setOnWin(onWin) {
		this.onWinEvent = onWin;
		return this;
	}

	setOnBreakDone(onBreakDone) {
		this.onBreakDoneEvent = onBreakDone;
		return this;
	}

	setOnError(onError) {
		this.onErrorEvent = onError;
		return this;
	}

	setOnGameDisabled(onGameDisabled) {
		this.onGameDisabled = onGameDisabled;
		return this;
	}

	setOnGameData(onGameData) {
		this.onGameDataEvent = onGameData;
		onGameData(this.gameData);
		return this;
	}

	/*
    data --> {
		amount, 
		betAmount,
		winAmount,		
        client_salt,         
        config, 
        hash, 
        prev_client_salt, 
        pref_config, 
        prev_server_salt, 
        serverConfig:{
            bet:{
                min, 
                max, 
                afterDot, 
                hrAmount
            },
			houseEdge,
			bet_share,
			merchantId,
			game_uuid
        }, 
        started, 
        stats:{
            bets,
            losses,
            lossesN,
            payout,
            profit,
            ses_bets,
            ses_losses,
            ses_lossesN,
            ses_payout,
            ses_profit,
            ses_winN,
            winN
        }, 
        steps:[], 
		choice,
		balance,
        userData:{
            last_online,
            player_name
        },
        randomHistory:[
            {
                value:??, 
                result
            }
        ]
    }
    */
	async onLoad(data) {
		if (typeof data === "string") data = JSON.parse(lz.decompressFromBase64(data));
		await this.module.autoPlay.setBetConfig(data.serverConfig.bet);
		config.merchantId = data.serverConfig.merchantId;
		this.setGameData(data);
		this.gameData.active = true;
		if (this.onGameDataEvent) this.onGameDataEvent(this.gameData);
		this.module.history.setConfig(data);
		this.module.fairness.setData(data);
		this.module.fairness.setActive(!data.started);
		this.module.betAmount.setServerConfig(data.serverConfig);
		if (data.started) this.module.betAmount.setFuture(data.amount);
		this.module.betAmount.setBalance(data.balance);
		this.module.stats.setData(data.stats);
		if (this.onLoadEvent) this.onLoadEvent(this.gameData);
		if (this.module.loadDoneOf) this.module.loadDoneOf("socket", data);
		this.module.help.onLoad(data);
		if (!process.env || process.env.NODE_ENV !== "production") console.log("onLoad: ", data);
	}

	//data --> {amount, balance, config, started}
	onBet(data) {
		if (typeof data === "string") data = JSON.parse(lz.decompressFromBase64(data));
		this.setGameData(data);
		this.gameData.steps = [];
		this.gameData.lose = false;
		this.gameData.win = false;
		this.gameData.playCount++;
		this.gameData.active = true;
		if (this.activeTimer) clearTimeout(this.activeTimer);
		this.activeTimer = null;
		if (this.onGameDataEvent) this.onGameDataEvent(this.gameData);
		this.module.fairness.setActive(!data.started);
		this.module.betAmount.setBalance(data.balance);
		if (this.onBetEvent) this.onBetEvent(this.gameData);
		if (!process.env || process.env.NODE_ENV !== "production") console.log("onBet: ", data);
	}

	//data --> {amount, step, stepResult}
	onAction(data) {
		if (typeof data === "string") data = JSON.parse(lz.decompressFromBase64(data));
		this.gameData.steps.push(data.step);
		this.gameData.results.push(data.stepResult);
		this.gameData.active = true;
		if (this.activeTimer) clearTimeout(this.activeTimer);
		this.activeTimer = null;
		if (this.onGameDataEvent) this.onGameDataEvent(this.gameData);
		if (this.onActionEvent) this.onActionEvent(this.gameData);
		if (!process.env || process.env.NODE_ENV !== "production") console.log("onAction: ", data);
	}

	async onWin(data) {
		if (typeof data === "string") data = JSON.parse(lz.decompressFromBase64(data));
		this.setGameData(data);
		this.gameData.random = data.random;
		this.gameData.lose = data.result < 0;
		this.gameData.win = data.result > 0;
		this.gameData.playCount++;
		this.gameData.lastBetAmount = data.betAmount;
		this.gameData.lastWinAmount = data.winAmount;
		this.gameData.randomHistory.unshift({ value: data.random, result: data.result });
		this.gameData.randomHistory.splice(this.gameData.randomHistory.length - 1, 1);
		this.gameData.amountInWay = this.module.betAmount.addAmountInWay(data.winAmount, data.betAmount);
		this.gameData.amountInWay.start();
		this.gameData.active = true;
		if (this.activeTimer) clearTimeout(this.activeTimer);
		this.activeTimer = null;
		if (this.onGameDataEvent) this.onGameDataEvent(this.gameData);
		this.module.fairness.setData(data);
		this.module.fairness.setActive(!data.started);
		this.module.betAmount.setBalance(data.balance, false);
		if (this.gameData.stats) this.module.stats.setData(this.gameData.stats);
		if (this.onWinEvent) this.onWinEvent(data);
		//this.module.autoPlay.setUseStatus();
		this.module.autoPlay.setResult({ win: data.result >= 0, balance: data.balance });
		if (!process.env || process.env.NODE_ENV !== "production") console.log("onWin: ", data);
	}

	onGameDisabled(data) {
		if (typeof data === "string") data = JSON.parse(lz.decompressFromBase64(data));
		this.gameData.active = true;
		this.module.notification(translate("game_is_deactive"));
	}

	onError(data) {
		if (typeof data === "string") data = JSON.parse(lz.decompressFromBase64(data));
		if (data.event && (data.event === "onBet" || data.event === "onWin" || data.event === "onEnd" || data.event === "onAction")) {
			this.gameData.active = true;
			if (this.activeTimer) clearTimeout(this.activeTimer);
			this.activeTimer = null;
		}
		if (this.onErrorEvent) this.onErrorEvent(this.gameData);
		console.log("onError: ", data);
	}

	onBreakDone(data) {
		if (typeof data === "string") data = JSON.parse(lz.decompressFromBase64(data));
		this.gameData.started = false;
		if (this.module.autoPlay.isRolling()) if (data.action === "refund") this.module.autoPlay.setResult({ error: true });
		if (this.onBreakDoneEvent) this.onBreakDoneEvent(this.gameData);
		console.log("onBreakDone: ", data);
	}
}

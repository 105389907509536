import React, { Component } from "react";

export default class Toggler extends Component {
	setVisible(visible) {
		if (arguments.length === 0) visible = !this.props.visible;
		this.setState({ visible });
		return this;
	}

	switch = (e, value) => {
		e.stopPropagation();
		if (typeof this.props.onToggle === "function") {
			this.props.onToggle(this.props.id, value);
		}
	};

	render() {
		if (this.props.visible === false) return null;
		return (
			<button className={`btnDefault toggler ${this.props.disabled ? "disabled" : ""}`} onClick={e => this.switch(e, 1 - this.props.value)}>
				<label className={`caption ${this.props.value === 0 ? "active" : "pasive"}`}> {this.props.captions[0]} </label>
				<label className={`caption ${this.props.value === 1 ? "active" : "pasive"}`}> {this.props.captions[1]} </label>
			</button>
		);
	}
}

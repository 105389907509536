import React from "react";
import { translate } from "../../providers/lang";

const Loader = (props = {}) => {
	return (
		<div className="bounded-wave-loader">
			<label>{`${translate("loading").toUpperCase()} ...`}</label>
			<svg xmlns="http://www.w3.org/2000/svg" width="240" height="40" fill="DodgerBlue" viewBox="0 0 120 10">
				<path d="M0,5 C20,-10 40,20 60,5 v5 H0" />
				<path transform="translate(60)" d="M0,5 C20,-10 40,20 60,5 v5 H0" />
			</svg>
		</div>
	);
};

export default Loader;

import { Component } from "react";

import { getSocket } from "../../providers/socket";
import { config, isProd } from "../../config";
import { hasParent } from "../../utills";

export class GameReactComponent extends Component {
	constructor(props) {
		super(props);
		if (isProd() && !hasParent()) return;
		config.apiUrlBack = config.http.urlBack.replace("*", props.api);
		config.apiUrlFront = config.http.urlFront.replace("*", props.api);
		getSocket(props.session_id, config.apiUrlBack);
	}
}

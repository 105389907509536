import { Howl, Howler } from "howler";

export class SoundsManager {
	constructor() {
		this.sounds = {};
		this.mute = false;
		Howler.volume(0.25);
	}

	add(name, address, count, sprite) {
		return this.load(name, address, count, sprite);
	}

	load(name, address, count, sprite) {
		if (!Array.isArray(address)) address = [address];
		if (count) for (let i = 0; i < count; i++) for (let k = 0; k < address.length; k++) this.load(name, address[k].replace("{i}", i));
		else {
			if (!this.sounds[name]) this.sounds[name] = [];
			const obj = { src: address };
			if (sprite) obj.sprite = sprite;
			this.sounds[name].push(new Howl(obj));
		}
		return this;
	}

	play(name, delay = 0, index = 0, sprite = "") {
		if (this.mute) return this;
		if (delay > 0) return setTimeout(() => this.play(name, 0, index), delay * 1000);
		try {
			if (sprite) this.sounds[name][index % this.sounds[name].length].play(sprite);
			else this.sounds[name][index % this.sounds[name].length].play();
		} catch (err) {}
		return this;
	}

	playSprite(name, sprite, delay = 0, index = 0) {
		return this.play(name, delay, index, sprite);
	}

	playRandom(name, delay = 0, startIndex = 0, count = 0, sprite = "") {
		if (count <= 0) count = this.sounds[name].length;
		const index = startIndex + Math.floor(Math.random() * count);
		this.play(name, delay, index, sprite);
		return this;
	}

	setVolume(volume = 1) {
		Howler.volume(volume);
		return this;
	}

	setMute(mute) {
		if (arguments.length === 0) this.mute = !this.mute;
		else this.mute = mute;
		if (this.mute) for (let k in this.sounds) for (let i = 0; i < this.sounds.length; i++) this.sounds[k][i].stop();
		return this;
	}

	do(fnc) {
		fnc(this);
		return this;
	}
}

import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Router from "./providers/router";

class App extends Component {
    render() {
        return (
            <div className="App">
                <Router />
            </div>
        );
    }
}

export default withRouter(App);

export const keywords = {
	en: {
		accepted_currencies: "Accepted currencies",
		account: "Account",
		activate: "Activate",
		active_rollhunts: "Active rollhunts",
		add: "Add",
		address: "Address",
		address_create_error: "Cannot create address",
		address_created: "Address created",
		address_not_generated_yet: "Address not generated yet",
		administrator: "Administrator",
		administrators: "Administrators",
		affiliate: "Affiliate",
		affiliate_casino_loss: "Casino loss from referred users",
		affiliate_casino_revenue: "Casino revenue from referred users",
		aff_claims: "Affiliation claims",
		affiliate_commission_rate: "Current referral comission rate",
		affiliate_current_balance: "Current balance of the referred users",
		affiliate_deposit_count: "Number of deposits",
		affiliate_net_commission: "Net commission",
		affiliate_referral_profit: "Net referral profit",
		affiliate_stats: "Affiliate Stats",
		affiliate_user_count: "Current number of referred users",
		all_bets: "All bets",
		all_in: "All-in",
		amount: "Amount",
		amount_not_number: "Amount is not a number",
		amount_per_user: "Amount per user",
		approved: "Approved",
		are_you_sure: "Are you sure",
		automated_betting: "Automated",
		auto: "Auto",
		auto_roll: "Auto roll",
		avatar: "Avatar",
		balance: "Balance",
		balance_over_limit_reached: "Balance over limit reached",
		balance_under_limit_reached: "Balance under limit reached",
		bank: "Bank",
		bet: "Bet",
		bets: "Bets",
		bet_amount: "Bet amount",
		bet_amount_limit_reached: "Bet amount limit reached",
		bet_info: "Bet info",
		bets_made_with_pair: "Bets made with pair",
		big: "Big",
		black: "Black",
		block: "Block",
		bonus_activated: "Bonus activated",
		bonus_activation_error: "Bonus activation error",
		bonus_claim: "Claim bonus",
		bonus_requires_deposit_in_btc: "To activate bonus you need 1 deposit in BTC",
		bonus_requires_deposit_in_bcc: "To activate bonus you need 1 deposit in BCC",
		bonus_requires_mail_verification: "To activate bonus you need verified mail account",
		buy: "Buy",
		cancel: "Cancel",
		cause_rain: "Cause rain",
		caution: "Caution",
		clear: "Clear",
		chance: "Chance",
		change_mail: "Change mail",
		change_client_seed: "Change client seed",
		change_password: "Change password",
		chat_messages: "Chat messages",
		check_input_values: "Check input values",
		check_internet: "Please check your internet connection",
		chips: "Chips",
		choose_coin: "Choose coin",
		claim: "Claim",
		client_seed: "Client seed",
		closed: "Closed",
		coin: "Coin",
		coin_management: "Coin management",
		comment: "Comment",
		confirm_new_password: "Confirm new password",
		confirmation_link_sent: "Confirmation link sent",
		contact_support: "For more details, contact support",
		contests: "Contests",
		copy_to_clipboard: "Copy to clipboard",
		create: "Create",
		created_by: "Created by",
		creator_profit: "Creator's profit",
		currency: "Currency",
		current_seed_pair: "Current seed pair",
		current_session: "Cur. session",
		date_joined: "Date joined",
		deactivate: "Deactivate",
		delay: "Delay",
		delete: "Delete",
		deposit: "Deposit",
		deposits: "Deposits",
		deposit_success: "Deposit success, please wait for 1 confirmation",
		deposit_confirmed: "Deposit success",
		deposits_are_created_within:
			"Deposits are credited within 1 confirmation depending on risk. Most deposits are credited within 30 seconds.",
		dice: "Dice",
		disabled: "Disabled",
		divide_amount: "Divide amount",
		double_check_rates: "Please double check if the current exchange rate fits your needs, because exchange is an irreversible action.",
		duration: "Duration",
		edit: "Edit",
		embed_code: "Embed code (for websites)",
		enabled: "Enabled",
		end: "End",
		enter_password: "Enter password",
		entry_not_found: "Entry not found",
		entry_add: "Add entry",
		entry_added: "Entry added",
		entry_remove: "Remove entry",
		entry_removed: "Entry removed",
		entry_update: "Update entry",
		entry_updated: "Entry updated",
		exchange: "Exchange",
		exchanges: "Exchanges",
		exchange_success: "Successfully exchanged amount",
		error: "Error",
		even: "Even",
		every_losses: "Every losses",
		every_wins: "Every wins",
		fa_if_enabled: "2FA (if enabled)",
		fairness: "Fairness",
		fairness_check: "Fairness check",
		faq: "FAQ",
		faucet: "Faucet",
		faucet_abuse:
			"Due to the faucet abuse or faucet farming activities, you cannot participate in the roll hunts, your tipping functionality is temporarily disabled",
		faucet_claimed: "Faucet claimed successfully",
		faucet_claimed_recently: "You have recently claimed from the faucet and must wait before claiming again.",
		faucet_claims: "Faucet claims",
		faucets_in_hour: "Faucet claims in 1 hour to regard player as a faucet farmer",
		faucet_management: "Faucet management",
		faucet_not_claimed: "Could not claim faucet",
		filter: "Filter",
		fingerprint: "Fingerprint",
		fingerprints: "Fingerprints",
		finish_to_reveal: "Finish game to reveal seed",
		field_blank: "Field cannot be empty",
		for: "for",
		forgot_password: "Forgot password",
		game: "Game",
		generate_new: "Generate new",
		gfa_management: "Google 2FA management",
		gfa_on: "Google 2FA activated",
		gfa_off: "Google 2FA deactivated",
		goblins_diamonds: "Goblin's Diamonds",
		h: "h",
		high_rollers: "High rollers",
		history: "History",
		home: "Home",
		hotkeys: "Hotkeys",
		hotkeys_are_currently: "Hotkeys are currently",
		house_edge: "House edge",
		hunt: "Hunt",
		hunt_number: "Hunt number",
		hunt_number_pool: "Hunt number pool",
		in_a_row: "In a row",
		increase_by: "Increase by",
		infinite: "Infinite",
		insufficient_funds: "Insufficient funds",
		insufficient_privileges: "Insufficient privileges",
		invalid_address: "Invalid address",
		invalid_amount: "Invalid amount",
		invalid_arguments: "Invalid arguments",
		invalid_bet_amount: "Invalid bet amount",
		invalid_currency: "Invalid currency",
		invalid_login_information: "Invalid login information",
		invalid_mail: "Not a valid email",
		invalid_password: "Invalid password",
		invalid_time: "Invalid time",
		invalid_token: "Invalid token",
		invalid_username: "Invalid username",
		invalid_wallet_password: "Invalid wallet password",
		investment_required: "Investment required",
		jackpot: "Jackpot",
		jackpots: "Jackpots",
		jackpot_rules:
			"Win in case every digit of the lucky number is present in the roll number\nThe Jackpot depends on the Bet amount:\nOver 0.001/0.01 BTC/BCC: 100%\nOver 0.0001/0.001 BTC/BCC 10%\nOver 0.00001/0.0001 BTC/BCC 1%\nThe Jackpot is not eligible for users betting less than 0.00001 BTC 0.000001 BCC",
		join: "Join",
		join_price: "Join price",
		leaderboard: "Leaderboard",
		let_others_know_about_this_tip: "Let others know about this tip",
		level: "Level",
		loading: "Loading",
		login: "Login",
		logout: "Logout",
		lose: "Lose",
		losses: "Losses",
		luck: "Luck",
		lucky_number: "Today's lucky number",
		mail_management: "Mail management",
		mail_set: "Recovery mail set, confirmation has been sent to",
		mail_not_found: "Mail not found",
		mail_not_confirmed: "Mail not confirmed",
		mail_not_set: "Mail not set",
		manual_betting: "Manual",
		marked_for_delete: "Marked for deletion",
		max: "Max",
		max_amount: "Maximum amount",
		message: "Message",
		min: "Min",
		min_amount: "Minimum amount",
		min_left: "{0} min left",
		minutes: "Minutes",
		minimal_exhcange_amount_is: "Minimal exchange amount is",
		minimal_faucet_amount_is: "Received amount from faucet is",
		minimal_withdraw_amount_is: "Minimal withdrawal amount is",
		minute: "Min",
		moderator: "Мoderator",
		moderator_profit: "Moderator's profit",
		moderators: "Мoderators",
		multiply_amount: "Мultiply amount",
		multiplier: "Multiplier",
		mute: "Mute",
		mute_sm: "Mute",
		muted: "Muted",
		my_bets: "My bets",
		my_recovery_mail: "My recovery mail",
		new: "New",
		new_message: "New message",
		new_messages: "New messages",
		new_password: "New password",
		new_password_min6: "New password  (min. 6 characters)",
		next_step: "Next step",
		no: "No",
		no_data_found: "No data found",
		no_limit: "no limit",
		not_implemented: "Current function not implemented",
		not_logged_in: "You are not logged in",
		not_revealed_yet: "The seed hasn't been revealed yet",
		number_of_players: "Number of players",
		number_of_roll_limit_reached: "Number of roll limit reached",
		number_of_rolls: "Number of rolls",
		odd: "Odd",
		old_password: "Old password",
		on_loss: "On loss",
		on_win: "On win",
		online: "Online",
		only: "Only",
		opened: "Opened",
		or: "Or",
		outcome: "Outcome",
		overall_stats: "Overall stats",
		pages: "Pages",
		participants: "Participants",
		participate: "Participate",
		password: "Password",
		password_management: "Password management",
		password_at_least: "Password must be at least 6 characters long",
		passwords_not_match: "passwords do not match",
		password_not_set: "Password not set",
		password_set: "Password set",
		password_changed: "Password changed",
		payout: "Payout",
		pending: "Pending",
		permissions: "Permissions",
		permissions_management: "Permissions management",
		place: "Place",
		play: "Play",
		player_has_been_muted: "Player has been muted",
		player_has_been_unblocked: "Player has been unblocked",
		previous_seed_pair: "Previous seed pair",
		prize_pool: "Prize pool",
		probability: "Probability",
		processed: "Processed",
		profile: "Profile",
		profit: "Profit",
		profit_from_jackpot: "Profit from jackpot",
		profit_from_rollhunt: "Profit from rollhunt",
		profit_on_win: "Profit on win",
		provide_password: "Please provide password",
		quantity: "Quantity",
		rain: "Rain",
		rain_received: "You got caught in the rain",
		rain_sent: "You caused the rain",
		rains_sent: "Rains sent",
		rains_received: "Rains received",
		randomize: "Randomize",
		randomise_to_reveal: "Randomise to reveal",
		randomization_error: "Error while changing client seed",
		randomization_failed: "Randomization failed",
		randomization_success: "Client seed successfully changed",
		reason: "Reason",
		rebet: "Rebet",
		recover: "Recover",
		recovery_password_sent: "Recovery password sent",
		red: "Red",
		referral_link: "Refferal link",
		ref_by: "Ref. by",
		reg_date: "Reg. date",
		registration_successful: "registration successful",
		rejected: "Rejected",
		resend_confirmation_link: "Resend confirmation link",
		reset: "Reset",
		reset_to_base: "Reset to base",
		reverse: "Reverse",
		roll: "Roll",
		rolls: "Rolls",
		roll_dice: "Roll dice",
		roll_over: "Roll over",
		roll_under: "Roll under",
		roll_hunt: "Roll hunt",
		room: "Room",
		room_create: "Create room",
		room_name: "Room name",
		rooms: "Rooms",
		rooms_management: "Rooms management",
		search: "Search",
		sec: "Sec",
		seconds: "Seconds",
		seconds_remaining: "Seconds remaining",
		send: "Send",
		send_tip: "Send tip",
		server_error: "Server error",
		server_seed: "Server seed",
		server_seed_changed: "Server seed changed",
		server_sha_512: "Server seed sha-512 hash",
		server_time: "Server time",
		set: "Set",
		set_password: "Set password",
		set_password_first: "Set password first",
		set_mail: "Set mail",
		set_recovery_mail: "Set a recovery mail",
		settings: "Settings",
		settings_saved: "Settings saved",
		slow_down: "Too many requests, slow down",
		small: "Small",
		something_went_wrong: "Something went wrong",
		speed: "Speed",
		spin: "Spin",
		statistics: "Statistics",
		start_playing: "Start playing",
		starts_after: "Starts after",
		status: "Status",
		stop: "Stop",
		stop_on_loss: "Triggered stop on loss",
		stop_on_win: "Triggered stop on win",
		stop_if_balance_under: "Stop if balance under",
		stop_if_balance_over: "Stop if balance over",
		stop_if_bet_amount_over: "Stop if bet amount over",
		thanks_for_playing:
			"Dear user thanks for playing with CasinoRoyale !   You are eligible for faucet every 3 minutes, if you are out of balance.",
		the_most_popular: "The most fair and trusted crypto gambling portal",
		take_seat: "Take seat",
		time: "Time",
		time_left: "Тime left",
		tip_amount: "Tip amount",
		tip: "Tip",
		tip_received: "Tip received",
		tip_sent: "Tip sent",
		tips_sent: "Tips sent",
		tips_received: "Tips received",
		tip_to: "Tip to",
		token: "Token",
		token_expired: "Your token expired, please relogin",
		toggle_jackpot: "Toggle jackpot",
		toggle_fa_mode: "Toggle 2FA mode",
		toggle_rollhunt: "Toggle rollhunt",
		toggle_slider: "Toggle slider",
		total_bets: "Total bets",
		total_losses: "Total losses",
		total_bets_shared: "Total bets shared",
		total_messages: "Total messages",
		total_profit: "Total profit",
		total_wagered: "Total wagered",
		total_wins: "Total wins",
		transaction_fee: "Transaction fee",
		transaction: "Transaction",
		transactions: "Transactions",
		transaction_complete: "Тransaction complete",
		translate: "Translate",
		turn_on: "Turn on",
		turn_off: "Turn off",
		two_factor_authentication: "Two factor authentication",
		type_a_message: "Type a message",
		type_your_mail: "Type your mail",
		unblock_sm: "Unblock",
		unexpected_error: "Unexpected error occured",
		user: "User",
		username_already_taken: "Username already taken",
		username_min6: "Username  (min. 6 characters)",
		username_not_found: "User not found",
		usernames: "Usernames",
		verify: "Verify",
		w_requests: "W. requests",
		wagering_contest: "Wagering contest",
		waiting_time: "Waiting time",
		wallet_not_found: "Wallet not found",
		websockets_support: "Your browser does not support websockets",
		welcome: "Welcome",
		win: "Win",
		wins: "Wins",
		win_chance: "Win chance",
		winner: "Winner",
		with_mail: "With mail",
		withdraw: "Withdraw",
		withdraw_amount_more: "Withdrawal amount should be more than",
		withdraw_at_least: "Withdrawal must be more or equal to 0.00400000 BTC",
		withdraw_error: "Withdrawal error, please, contact support",
		withdraw_success: "Successfully withdrew amount",
		withdrawals: "Withdrawals",
		yes: "Yes",
		you_are_offline: "You are Offline",
		you_are_not_eligible_for_chat:
			"To be eligible for chat one should satisfy the following terms: player should have pasword, mail, account should be at least 24 hours old, minimum 0.0005 BTC wagering is required",
		you_have_been_muted_by: "You have been muted by",
		you_pay: "You pay",
		you_receive: "You receive",
		your_comment: "Your comment",
		your_place: "Your place",
		your_recovery_mail: "Your recovery mail",
		you_wagered: "You wagered",
		your_hunt_number_pool_is_empty: "Your hunt number pool is empty",
		your_withdrawal_will_be_sent: "Your withdrawal will be sent once there are no unconfirmed deposits on your account.",
	},
	ru: {
		accepted_currencies: "Встроенные валюты",
		account: "Профиль",
		activate: "Включить",
		active_rollhunts: "Активные охоты",
		add: "Добавить",
		address: "Адрес",
		address_create_error: "Ошибка при создании адреса",
		address_created: "Адрес создался",
		address_not_generated_yet: "Адрес пока не создан",
		administrator: "Администратор",
		administrators: "Администраторы",
		affiliate: "Рефералы",
		affiliate_casino_loss: "Расходы казино от рефералов",
		affiliate_casino_revenue: "Доход казино от рефералов",
		aff_claims: "Доход от рефералов",
		affiliate_commission_rate: "Ставка комиссии",
		affiliate_current_balance: "Текущий баланс рефералов",
		affiliate_deposit_count: "Количество вкладов",
		affiliate_net_commission: "Чистая комиссия",
		affiliate_referral_profit: "Чистая реферальная прибыль",
		affiliate_stats: "Статистика рефералов",
		affiliate_user_count: "Текущее количество рефералов",
		all_bets: "Все ставки",
		avatar: "Аватар",
		all_in: "Поставить всё?",
		amount: "Сумма",
		amount_not_number: "Сумма не в формате числа",
		amount_per_user: "Сумма на игрока",
		approved: "Утвержденный",
		are_you_sure: "Вы уверены?",
		auto: "Авто",
		automated_betting: "Автоматические",
		auto_roll: "Авто бросок",
		balance: "Баланс",
		balance_over_limit_reached: "Достигнут лимит верхнего значения баланса",
		balance_under_limit_reached: "Достигнут лимит нижнего значения баланса",
		bank: "Банк",
		bet: "Ставка",
		bets: "Ставки",
		bet_amount: "Размер ставки",
		bet_amount_limit_reached: "Достигнут лимит размера ставки",
		bet_info: "Информация о ставке",
		bets_made_with_pair: "Ставки сделанные парой",
		big: "Большие",
		black: "Черный",
		block: "Заблокировать",
		bonus_activated: "бонус активирован",
		bonus_requires_deposit_in_btc: "Активация бонуса требует наличия 1-го депозита в BTC",
		bonus_requires_deposit_in_bcc: "Активация бонуса требует наличия 1-го депозита в BCC",
		bonus_activation_error: "Ошибка активации бонуса",
		bonus_claim: "Запросить бонус",
		bonus_requires_mail_verification: "Активация бонуса требует потдверждение почты",
		buy: "Купить",
		cancel: "Отмена",
		cause_rain: "Вызвать дождь",
		caution: "Внимание",
		chance: "% выигр.",
		change_mail: "Смена почты",
		change_client_seed: "Смена сида клиента",
		change_password: "Смена пароля",
		chat_messages: "Всего сообщении",
		check_input_values: "Проверьте вводимые значения",
		check_internet: "Пожалуйста, проверьте ваше интернет подключение",
		chips: "Фишки",
		choose_coin: "Выберите монету",
		claim: "Запросить",
		clear: "Очистить",
		client_seed: "Сид клиента",
		closed: "Закрыт",
		comment: "Комментарий",
		coin: "Монета",
		coin_management: "Управление монетами",
		confirm_new_password: "Новый пароль заново",
		confirmation_link_sent: "Ссылка для подтверждения отправлена",
		contact_support: "Для деталей обратитесь в тех. службу",
		contests: "Конкурсы",
		copy_to_clipboard: "Перенести в буфер",
		create: "Создать",
		currency: "Валюта",
		current_seed_pair: "Текущая пара сидов",
		current_session: "Тек. сесия",
		date_joined: "День регистрации",
		deactivate: "Отключить",
		delay: "Задержка",
		delete: "Удалить",
		deposit: "Депозит",
		deposit_confirmed: "Депозит потверждён",
		deposits: "Депозиты",
		deposit_success: "Успешный депозит, пожалуйста подождите, нужна 1 конфирмация",
		deposits_are_created_within:
			"Депозиты создаются в течении одной конфирмации в зависимости от риска. В большинстве случаев депозиты появляются в течении 30 секунд",
		dice: "Кости",
		disabled: "Выключены",
		divide_amount: "Разделить сумму",
		double_check_rates:
			"Пожалуйста, проверьте, соответствует ли текущий обменный курс вашим потребностям, так как обмен является необратимым действием.",
		duration: "Длительность",
		edit: "Редактировать",
		embed_code: "Встроенный код (для вебсаитов)",
		enabled: "Включены",
		end: "Завершить",
		enter_password: "Введите пароль",
		entry_not_found: "Запись не найдена",
		entry_add: "Добавить запись",
		entry_added: "Запись добавлена",
		entry_remove: "Удалить запись",
		entry_removed: "Запись удалена",
		entry_update: "Обновить запись",
		entry_updated: "Запись обновлена",
		error: "Ошибка",
		even: "Четный",
		every_losses: "Каждые проигрыши",
		every_wins: "Каждые выигрыши",
		exchange: "Обмен монет",
		exchanges: "Обмены монет",
		exchange_success: "Вы успешно произвели обмен монет",
		fa_if_enabled: "2FA (если активирован)",
		fairness: "Честность",
		fairness_check: "Проверить честность",
		faucet: "Кран",
		faucet_abuse:
			"Из-за неправильного использования крана, вы не можете учавствовать в охотах бросков, ваша возможность выдачи чаевых была временно приостановлена",
		faucet_claimed: "Кран успешно задействован",
		faucet_claimed_recently: "Вы недавно запросили кран и должны подождать перед следующим запросом",
		faucet_claims: "Доход с крана",
		faucet_management: "Управление краном",
		faucet_not_claimed: "Не удалось запросить пополнение баланса из крана (баланс должен быть = 0)",
		faucets_in_hour: "Количество запросов на кран в течении часа чтоб посчитать игрока кран-фермером",
		field_blank: "Поле не может быть пустым",
		filter: "Фильтр",
		fingerprint: "Отпечаток",
		fingerprints: "Отпечатки",
		finish_to_reveal: "Завершите игру чтоб раскрыть сид",
		for: "для",
		forgot_password: "Забыли пароль",
		game: "Игра",
		generate_new: "Сгенерировать новый",
		gfa_management: "Управление Google 2FA",
		gfa_on: "Google 2FA активирован",
		gfa_off: "Google 2FA деактивирован",
		goblins_diamonds: "Бриллианты Гоблина",
		h: "чс",
		high_rollers: "Высокие ставки",
		history: "История",
		home: "Home",
		hotkeys: "Горячие клавишы",
		hotkeys_are_currently: "Горячие клавишы в данный момент",
		hunt: "Охота",
		hunt_number: "Искомое число",
		hunt_number_pool: "Пул чисел для охоты",
		in_a_row: "Подряд",
		increase_by: "Увеличить на",
		infinite: "Вечно",
		insufficient_funds: "Недостаточно средств",
		insufficient_privileges: "Недостаточно прав",
		invalid_address: "Неправильный адрес",
		invalid_amount: "Неправильная сумма",
		invalid_arguments: "Неправильные аргументы",
		invalid_bet_amount: "Неправильная ставка",
		invalid_currency: "Неверная валюта",
		invalid_login_information: "Неверные параметры входа",
		invalid_mail: "Неправильный почтовой адрес",
		invalid_password: "Неправильный пароль",
		invalid_time: "Неправильное время",
		invalid_token: "Неправильный токен",
		invalid_username: "Неправильное имя пользователя",
		invalid_wallet_password: "Неправильный пароль кошелька",
		investment_required: "Необходимое количество вклада",
		jackpot: "Джекпот",
		jackpots: "Джекпоты",
		join: "Регистрация",
		join_price: "Цена участия",
		leaderboard: "Таблица лидеров",
		let_others_know_about_this_tip: "Дать знать другим",
		level: "Уровень",
		loading: "Загрузка",
		login: "Логин",
		logout: "Выйти",
		lose: "Проигрыш",
		losses: "Проигрыши",
		luck: "Удача",
		lucky_number: "Удачное число дня",
		mail_management: "Управление почтой",
		mail_not_confirmed: "Почта не подтверждена",
		mail_not_set: "Почта не установлена",
		mail_set: "Почтовой адрес установлен, конфирмация отправлена на",
		mail_not_found: "Почта не найдена",
		manual_betting: "Вручную",
		marked_for_delete: "Помечен для удаления",
		max: "Макс",
		max_amount: "Максимальная сумма",
		message: "Сообщение",
		min: "Мин",
		min_amount: "Минимальная сумма",
		min_left: "Ост. {0} мин",
		minutes: "Минут",
		minimal_exhcange_amount_is: "Минимальная сумма обмена составляет",
		minimal_faucet_amount_is: "Полученная сумма с крана составляет",
		minimal_withdraw_amount_is: "Минимальная сумма снятия составляет",
		minute: "Мин",
		moderator: "Модератор",
		moderator_profit: "Прибыль модератора",
		moderators: "Модераторы",
		multiplier: "Мультипликатор",
		multiply_amount: "Умножить сумму",
		mute: "Заглушить",
		mute_sm: "Заглуш.",
		muted: "Заглушён",
		my_bets: "Мои ставки",
		my_recovery_mail: "Моя почта",
		no: "Нет",
		new: "Новый",
		new_message: "новое сообщение",
		new_messages: "новых сообщения",
		new_password: "Новый пароль",
		new_password_min6: "Новый пароль (мин. 6 символов)",
		no_data_found: "Найдено 0 данных",
		no_limit: "без лимита",
		not_implemented: "Данная функция не разработана",
		not_logged_in: "Вы не залогинены",
		not_revealed_yet: "Сид пока не раскрыт",
		number_of_players: "Количество игроков",
		number_of_roll_limit_reached: "Лимит количества бросков достигнут",
		number_of_rolls: "Количество бросков",
		odd: "Нечетный",
		old_password: "Старый пароль",
		on_loss: "При проигрыше",
		on_win: "При выигрыше",
		online: "Онлаин",
		only: "Только",
		opened: "Открыт",
		or: "Или",
		outcome: "Результат",
		overall_stats: "Вся статистика",
		pages: "Страницы",
		participants: "Участники",
		password: "Пароль",
		password_management: "Управление паролем",
		password_at_least: "Длина пароля должна быть как минимум 6 символов",
		passwords_not_match: "Несовпадение паролей",
		password_not_set: "Пароль не установлен",
		password_set: "Пароль установлен",
		password_changed: "Пароль поменялся",
		participate: "Участвовать",
		payout: "Выплата",
		pending: "В ожидании",
		permissions: "Права",
		permissions_management: "Управление доступом",
		place: "Место",
		play: "Играть",
		player_has_been_muted: "Вы успешно заглушили игрока",
		player_has_been_unblocked: "Игрок разблокирован",
		previous_seed_pair: "Предыдущая пара сидов",
		prize_pool: "Приз",
		probability: "Вероятность",
		processed: "Обработан",
		profile: "Профиль",
		profit: "Прибыль",
		profit_from_jackpot: "Прибыль полученная от джекпота",
		profit_from_rollhunt: "Прибыль полученная от охоты бросков",
		profit_on_win: "Прибыль при выигрыше",
		provide_password: "Пожалуйста, введите пароль",
		quantity: "Количество",
		rain: "Дождь",
		rain_received: "Вы попали под дождь",
		rain_sent: "Вы вызвали дождь",
		rains_sent: "Отпр. дожди",
		rains_received: "Прин. дожди",
		randomize: "Случайный отбор",
		randomise_to_reveal: "Сделать случайный отбор для отображения",
		randomization_error: "Ошибка при смене клиентского сида",
		randomization_failed: "Ошибка при случайном отборе",
		randomization_success: "Сид клиента успешно поменялся",
		reason: "Причина",
		rebet: "Заново",
		recover: "Восстановить",
		recovery_password_sent: "Пароль для восстановления отправлен",
		red: "Красный",
		ref_by: "Реф. от:",
		referral_link: "Реферальная ссылка",
		reg_date: "День регистрации:",
		registration_successful: "Успешная регистрация",
		rejected: "Отменен",
		resend_confirmation_link: "Послать ссылку для активации",
		reset: "Сброс",
		reset_to_base: "Базовое значение",
		reverse: "Развернуть",
		roll: "Бросок",
		rolls: "Броски",
		roll_dice: "Бросить кости",
		roll_hunt: "Охота бросков",
		roll_over: "Выше",
		roll_under: "Ниже",
		room: "Комната",
		room_create: "Создать комнату",
		room_name: "Название комнаты",
		rooms: "Комнаты",
		rooms_management: "Управление комнатами",
		search: "Поиск",
		sec: "Сек",
		seconds: "Секунды",
		seconds_remaining: "осталось секунд",
		send: "Отправить",
		send_tip: "Отправить чаевые",
		server_error: "Серверная ошибка",
		server_seed: "Серверный сид",
		server_seed_changed: "Серверный сид поменялся",
		server_sha_512: "Хеш (sha-512) серверного сида",
		server_time: "Время сервера",
		set: "Установить",
		set_mail: "Установка почты",
		set_password: "Установка пароля",
		set_password_first: "Сперва установите пароль",
		set_recovery_mail: "Установка почты",
		settings: "Параметры",
		settings_saved: "Параметры сохранены",
		slow_down: "Слишком много запросов",
		small: "Маленькие",
		something_went_wrong: "Серверная ошибка",
		speed: "Скорость",
		spin: "Крутить",
		statistics: "Статистика",
		starts_after: "Начинается через",
		status: "Статус",
		stop: "Стоп",
		stop_on_loss: "Задействован стоп при проигрыше",
		stop_on_win: "Задействован стоп при выигрыше",
		stop_if_balance_under: "Остановить если баланс меньше",
		stop_if_balance_over: "Остановить если баланс больше",
		stop_if_bet_amount_over: "Остановить если величина ставки больше",
		thanks_for_playing:
			"Дорогой пользователь, спасибо что вы играите в CasinoRoyale !  Вы имеете право на кран каждые 3 минуты, если ваш баланс равен нулю",
		the_most_popular: "Самый справедливый и надёжный крипто игровой портал",
		time: "Время",
		time_left: "Оставшееся время",
		tip: "Чаевые",
		tip_amount: "Количество чаевых",
		tip_received: "Вы получили чаевые",
		tip_sent: "Чаевые отправлены",
		tips_sent: "Отпр. чаевые",
		tips_received: "Прин. чаевые",
		tip_to: "Отправить чаевые",
		toggle_jackpot: "Переключение джекпота",
		toggle_fa_mode: "Переключение 2FA",
		toggle_rollhunt: "Переключение охоты",
		toggle_slider: "Переключение ползунка",
		token: "Токен",
		token_expired: "Ваш токен истёк, пожалуйста, перелогиньтесь",
		total_bets: "Всего ставок",
		total_losses: "Количество проигрышей",
		total_bets_shared: "Количество общих ставок",
		total_messages: "Количество сообщении",
		total_profit: "Вся прибыль",
		total_wagered: "Весь оборот",
		total_wins: "Количество выигрышей",
		transaction_fee: "Kомиссия на перевод",
		translate: "Перевести",
		transaction: "Транзакция",
		transactions: "Транзакции",
		transaction_complete: "Транзакция завершена",
		turn_on: "Включить",
		turn_off: "Выключить",
		two_factor_authentication: "2 факт. авторизация (2FA)",
		type_a_message: "Введите сообщение",
		type_your_mail: "Введите ваш почтовый адрес",
		unblock_sm: "Разбл.",
		unexpected_error: "Случилась неожидаемая ошибка",
		user: "Пользователь",
		username_already_taken: "Имя пользователя уже изпользуется",
		username_min6: "Имя пользователя (мин. 6 символов)",
		username_not_found: "Имя пользователя не найдено",
		usernames: "Имена пользователей",
		verify: "Проверить",
		w_requests: "Запр. на вывод",
		wagering_contest: "Конкурс по обороту",
		waiting_time: "Время ожидания",
		wallet_not_found: "Кошелёк не найден",
		websockets_support: "Ваш браузер не поддерживает техонологию 'websockets'",
		welcome: "Добро пожаловать",
		win: "Выигрыш",
		wins: "Выигрыши",
		win_chance: "Вероятность выигрыша",
		winner: "Победитель",
		with_mail: "С почтой",
		withdraw: "Вывод",
		withdraw_amount_more: "Сумма снятия должна быть больше чем",
		withdraw_at_least: "Сумма снятия должна быть не меньше 0.00400000 BTC",
		withdraw_error: "Ошибка при снятии суммы, пожалуйста обратитесь в суппорт",
		withdraw_success: "Сумма успешно снялась",
		withdrawals: "Выводы",
		yes: "Да",
		you_are_offline: "Вы в офлаине",
		you_have_been_muted_by: "Вас временно заглушил",
		you_pay: "Вы платите",
		you_wagered: "Ваш оборот",
		your_place: "Ваше место",
		you_receive: "Вы получаете",
		your_comment: "Ваш комментарий",
		your_hunt_number_pool_is_empty: "Ваш пул для охоты пуст",
		your_recovery_mail: "Ваша почта",
		your_withdrawal_will_be_sent: "Вы получите снятую сумму сразу же, как только на вашем счету не будут не подтверждённых депозитов",
	},
};

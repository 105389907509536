import React from "react";

//components:
import Window from "../Window/Window";
import Input from "../../components/Input/Input";
//provider
import { getSocket } from "../../providers/socket";
import { translate } from "../../providers/lang";
import Decimal from "decimal.js";
import lz from "lz-string";
//utills:
//import { delay } from "../utills";

class BetAmount extends Window {
	constructor(props) {
		super(props);
		BetAmount.main = this;
		this.setTitle(translate("bet_amount"));
		this.setClassName("betAmountWindow");
		this.setCloseIcon("check");

		//properties ----------------------------
		this.onChangeEvent = []; // (amountText, amount, balanceText, balance)
		this.onBalanceEvent = []; // (balance, isFirst)
		this.value = 0;
		this.valueFuture = 0;
		this.text = "0";

		//bet config
		this.min = -1;
		this.max = 1;
		this.balance = 999;
		this.balanceText = "0";
		this.afterDot = 8;
		this.houseEdge = 0;
		this.currency = "";

		this.state = {
			v: props.value ? props.value : "0",
			active: false,
			maxConfirmation: false,
		};
		//----------------------------------------

		if (props.onInit) props.onInit(this);
		this.onSocketBalance = this.onSocketBalance.bind(this);
		const socket = getSocket();
		if (socket && !socket.hasListeners("onBalance")) socket.on("onBalance", this.onSocketBalance);
		super.endConstructor();
		this.setBalance(0);
		this.isFirstBalance = true;
	}

	addAmountInWay(amountIn, amountOut) {
		return new AmountInWay(amountIn, amountOut);
	}

	onSocketBalance(balance) {
		if (typeof balance === "string") balance = JSON.parse(lz.decompressFromBase64(balance));
		if (typeof balance === "number") this.setBalance(balance);
	}

	addOnChange(onChange, call = false) {
		this.onChangeEvent.push(onChange);
		if (call) onChange(this.text, this.value, this.balanceText, this.balance);
		return this;
	}

	addOnBalance(onBalance, call = false) {
		this.onBalanceEvent.push(onBalance);
		if (call) onBalance(this.balanceText, this.balance);
		return this;
	}

	onChange(e, checkMin) {
		this.set(e.target.value, checkMin);
	}

	get() {
		return this.value;
	}

	getBalance() {
		return new Decimal(this.balance).minus(AmountInWay.totalIn).toNumber();
	}

	getBalanceText() {
		let bal = this.getBalance();
		return this.valueToText(bal, false);
	}

	valueToText(value, showCurrency = true) {
		if (value === undefined) value = 0;
		if (typeof value === "string") value = parseFloat(value.toString());
		if (showCurrency) return `${value.toFixed(this.afterDot).substr(0, this.afterDot + 2)} ${this.getCurrency()}`;
		else return value.toFixed(this.afterDot).substr(0, this.afterDot + 2);
	}

	setRange(min, max) {
		this.min = min;
		this.max = max;
		this.set(this.value);
	}

	setServerConfig(serverConfig) {
		if (!serverConfig) return this;
		this.setMin(serverConfig.bet.min);
		this.setMax(serverConfig.bet.max);
		this.afterDot = serverConfig.bet.afterDot;
		this.houseEdge = serverConfig.houseEdge;
		this.currency = serverConfig.currency;
	}

	setMin(min) {
		this.min = min;
		this.set(this.value);
		return this;
	}

	setMax(max) {
		this.max = max;
		this.set(this.value);
		return this;
	}

	setBalance(balance, callEvent = true) {
		balance = parseFloat(balance);
		//const obj = { oldBalance: this.balance, balance };
		this.balance = balance;
		this.balanceText = parseFloat(balance.toString())
			.toFixed(this.afterDot)
			.substr(0, this.afterDot + 2);
		if (this.balance > 0 && this.value === 0) this.set(0, true);
		for (let fnc of this.onChangeEvent) if (fnc) fnc(this.text, this.value, this.balanceText, this.balance);
		if (this.valueFuture > 0) {
			this.set(this.valueFuture);
			this.valueFuture = 0;
		}
		if (this.isFirstBalance && this.props.parent.loadDoneOf) this.props.parent.loadDoneOf("balance");
		if (callEvent) for (let fnc of this.onBalanceEvent) if (fnc) fnc(this.balance, this.isFirstBalance);
		this.isFirstBalance = false;
		return this;
	}

	getMax() {
		return Math.min(this.max, this.balance);
	}

	getCurrency() {
		return this.currency.toUpperCase();
	}

	set(value, checkMin = true, callCount = 0) {
		if (typeof value === "function") return this.set(value(this.get()));
		if (value === Infinity) value = this.getMax();
		if (value instanceof Decimal) value = value.toNumber();
		if (value !== "" && value !== 0) this.text = new Decimal(value).toFixed(12).substr(0, this.afterDot + 2);
		else this.text = "0";
		this.value = parseFloat(this.text);
		let valueInRange = this.value;
		if (!this.props.parent.gameSocket || !this.props.parent.gameSocket.gameData || !this.props.parent.gameSocket.gameData.started) {
			valueInRange = Math.min(this.value, this.getMax());
			if (checkMin) valueInRange = Math.max(this.min, valueInRange);
			if (this.getMax() === 0) valueInRange = 0;
		}
		if (isNaN(this.value) || isNaN(valueInRange)) console.error("this.value: ", this.value, ", valueInRange: ", valueInRange);
		if (new Decimal(this.value).toFixed(12) !== new Decimal(valueInRange).toFixed(12)) return this.set(valueInRange, true, callCount + 1);
		if (typeof value === "number") value = this.text;
		this.setState({ v: value });
		for (let fnc of this.onChangeEvent) if (fnc) fnc(this.text, this.value, this.balanceText, this.balance);
		return this;
	}

	setFuture(value) {
		if (this.balance > 0 || this.props.parent.gameSocket.gameData.started) return this.set(value);
		this.valueFuture = value;
		return this;
	}

	hide(e) {
		if (e) e.stopPropagation();
		this.showMaxConfirm(false);
		const oldValue = this.value;
		this.set(this.value, true);
		if (oldValue === this.value) return super.hide(e);
		return this;
	}

	// show() {
	// 	super.show();
	// 	document.getElementById("betAmountInput").focus();
	// 	return this;
	// }

	showMaxConfirm(show) {
		this.setState({ maxConfirmation: show });
	}

	beforeMax(e) {
		e.stopPropagation();
		this.showMaxConfirm(true);
	}

	componentDidUpdate = (prevState, prevProps) => {
		if (this.state.visible && this.state.visible !== prevState.visible && !this.props.parent.isMobileOrTablet) {
			document.getElementById("betAmountInput").focus();
		}
	};

	renderContent() {
		return (
			<div className="betAmount">
				<div className={`showMaxConfirm ${this.state.maxConfirmation ? "show" : "hide"} w100`}>
					<label className="labelAllIn">
						{translate("all_in")} [<b>{this.valueToText(this.getMax(), true)}</b>]?
					</label>
					<div className="confirmButtons flex_h w100 flex_c_b p10_all">
						<button onClick={(e) => this.set(Infinity).showMaxConfirm(false)} className="btnDefault btn btnRed">
							{translate("yes")}
						</button>
						<button onClick={(e) => this.showMaxConfirm(false)} className="btn btnDefault">
							{translate("no")}
						</button>
					</div>
				</div>
				<Input
					id="betAmountInput"
					type={this.props.parent.isMobileOrTablet ? "number" : "text"}
					pattern={"[0-9.]*"}
					value={this.state.v}
					maxLength="10"
					validation="amount"
					onChange={(e) => this.onChange(e, false)}
					onBlur={(e) => this.onChange(e, true)}
					placeholder={translate("bet_amount")}
					disabled={this.state.maxConfirmation}
				/>
				<div className="buttonsPanel">
					<button onClick={(e) => this.set(0)} disabled={this.state.maxConfirmation} className="btn btnDefault">
						{translate("min")}
					</button>
					<button onClick={(e) => this.set((v) => v / 2)} disabled={this.state.maxConfirmation} className="btn btnDefault">
						{translate("half")}
					</button>
					<button onClick={(e) => this.set((v) => v * 2)} disabled={this.state.maxConfirmation} className="btn btnDefault">
						{translate("double")}
					</button>
					<button onClick={(e) => this.beforeMax(e)} disabled={this.state.maxConfirmation} className="btnDefault btn btnRed">
						{translate("max")}
					</button>
				</div>
			</div>
		);
	}
}

BetAmount.main = null;

class AmountInWay {
	constructor(amountIn, amountOut = 0) {
		this.amountIn = amountIn;
		this.amountOut = amountOut;
		this.delta = new Decimal(amountIn).minus(amountOut).toNumber();
		this.active = false;
	}

	start() {
		if (this.active) return this;
		this.active = true;
		AmountInWay.totalIn += this.amountIn;
		AmountInWay.totalOut += this.amountOut;
		AmountInWay.total = AmountInWay.totalIn - AmountInWay.totalOut;
		return this;
	}

	finish() {
		if (!this.active) return this;
		this.active = false;
		AmountInWay.totalIn -= this.amountIn;
		AmountInWay.totalOut -= this.amountOut;
		AmountInWay.total = AmountInWay.totalIn - AmountInWay.totalOut;
		const ba = BetAmount.main;
		if (ba) for (let fnc of ba.onBalanceEvent) if (fnc) fnc(ba.balance, ba.isFirstBalance);
		return this;
	}
}

AmountInWay.totalIn = 0;
AmountInWay.totalOut = 0;
AmountInWay.total = 0;

export default BetAmount;

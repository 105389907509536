import React from "react";
//other:
import { Decimal } from "decimal.js";

const globalPatterns = {
	amount: (data, result) => {
		if (!/^[0-9]{0,}?(\.|,)?[0-9]{0,8}$/g.test(data)) return false;
		if (data.charAt(0) === "." || data.charAt(0) === ",") return false; //.234
		if (data.indexOf(".") === -1 && data.indexOf(",") === -1 && data.startsWith("0") && data.length > 1) return false; //0123
		if ((data.indexOf(".") > 1 || data.indexOf(",") > 1) && data.startsWith("0")) return false; //01.
		result.replaceComma = true;
		return true;
	},
	amount2: data => {
		if (!/^[0-9]{0,}?\.?[0-9]{0,8}$/g.test(data)) return false;
		return true;
	},
	negativeAmount: (data, result) => {
		if (!/^-?[0-9]{0,}?(\.|,)?[0-9]{0,8}$/g.test(data)) return false;
		if (data.charAt(0) === "." || data.charAt(0) === ",") return false; //.234
		if (data.indexOf(".") === -1 && data.indexOf(",") === -1 && data.startsWith("0") && data.length > 1) return false; //0123
		if ((data.indexOf(".") > 1 || data.indexOf(",") > 1) && data.startsWith("0")) return false; //01.
		if (data.indexOf("-") > 0 || data.indexOf("-.") !== -1 || data.indexOf("-,") !== -1 || data.indexOf("-00") !== -1) return false; //-, 2342- , -.
		if (data.indexOf("-0") !== -1 && data.indexOf(".") !== 2 && data.indexOf(",") !== 2 && data.length > 2) return false; //-01
		result.replaceComma = true;
	},
	chance: (data, result) => {
		if (!/^[0-9]{0,}?(\.|,)?[0-9]{0,3}$/g.test(data)) return false;
		if (data.length > 2 && data.indexOf(".") === -1 && data.indexOf(",") === -1) return false;
		if (data === "0.00" || data === "0,00") return false;
		result.replaceComma = true;
		return true;
	},
	coinName: data => /^[a-zA-Z\s]{0,20}$/g.test(data) || data === "",
	searchKeyword: data => /^[a-zA-Zа-яА-Я0-9]{0,1}[a-zA-Zа-яА-Я0-9]{0,50}$/g.test(data) || data === "",
	integer: data => data === "" || (parseInt(data) + "" === data && !isNaN(data)),
	payout: (data, result) => {
		if (!/^[0-9]{0,}?(\.|,)?[0-9]{0,3}$/g.test(data)) return false;
		if (data.indexOf(".") > 4 || data.indexOf(",") > 4) return false;
		if (data.length > 4 && data.indexOf(".") === -1 && data.indexOf(",") === -1) return false;
		result.replaceComma = true;
		return true;
	},
	password: data => /^[a-zA-Z0-9!@#$%^&*()_+\-=]{0,12}$/gi.test(data),
	percent: (data, result) => {
		if (!/^-?[0-9]{0,}?(\.|,)?[0-9]{0,2}$/g.test(data)) return false;
		if (data.charAt(0) === "." || data.charAt(0) === ",") return false; //.234
		if (data.indexOf(".") === -1 && data.indexOf(",") === -1 && data.startsWith("0") && data.length > 1) return false; //0123
		if ((data.indexOf(".") > 1 || data.indexOf(",") > 1) && data.startsWith("0")) return false; //01.
		if (data.indexOf("-") > 0 || data.indexOf("-.") !== -1 || data.indexOf("-,") !== -1 || data.indexOf("-00") !== -1) return false; //-, 2342- , -.
		if (data.indexOf("-0") !== -1 && data.indexOf(".") !== 2 && data.indexOf(",") !== 2 && data.length > 2) return false; //-01
		result.replaceComma = true;
		return true;
	},
	salt: data => {
		if (!/^[a-zA-Z0-9!@#$%^&*()_+\-=[\].,;:/|><?{}]{0,24}$/gi.test(data)) return false;
		return true;
	},
	rollValue: (data, result) => {
		if (!/^[0-9]{0,}?(\.|,)?[0-9]{0,2}$/g.test(data)) return false;
		if (data.indexOf(".") > 2 || data.indexOf(",") > 2) return false;
		if (data.length > 2 && data.indexOf(".") === -1 && data.indexOf(",") === -1) return false;
		if (data === "0.00") return false;
		result.replaceComma = true;
		return true;
	},
	short: data => {
		if (!/^[A-Z]{0,5}$/g.test(data) && data !== "") return false;
		return true;
	},
	roomName: data => {
		if (!/^[a-zA-Z]{0,1}[a-z0-9-_]{0,4}$/gi.test(data) && data !== "") return false;
		return true;
	},
	userName: data => {
		if (!/^[a-zA-Z]{0,1}[a-z0-9-_]{0,11}$/gi.test(data) && data !== "") return false;
		return true;
	}
};

const validate = (data, patterns, input) => {
	const result = { test: true, replaceComma: false };
	patterns.forEach(pattern => {
		if (typeof pattern === "string") {
			if (!globalPatterns[pattern](data, result, input)) result.test = false;
		} else if (!pattern(data, result, input)) result.test = false;
	});
	return result;
};

const onEventValidation = (e, validation, f, input) => {
	if (validation) {
		let patterns;
		if (Array.isArray(validation)) patterns = validation;
		else patterns = validation.indexOf("|") > -1 ? validation.split("|") : [validation];
		const result = validate(e.target.value, patterns, input);
		if (result.test) {
			if (result.replaceComma) e.target.value = e.target.value.replace(",", ".");
			if (f) f(e);
			else return true;
		} else {
			return false;
		}
	} else {
		if (f) f(e);
	}
};
const onBlurValidation = (e, f, min, max, toFixed) => {
	//change value
	if (min !== null || max !== null || toFixed !== null) {
		const dValue = new Decimal(e.target.value === "" || e.target.value === "-" ? 0 : e.target.value);
		if (toFixed !== null) e.target.value = dValue.toFixed(toFixed);
		if (min !== null && dValue.lessThan(min)) {
			e.target.value = toFixed === null ? min : new Decimal(min).toFixed(toFixed);
		} else if (max !== null && dValue.greaterThan(max)) {
			e.target.value = toFixed === null ? max : new Decimal(max).toFixed(toFixed);
		} else {
			if (toFixed !== null) e.target.value = dValue.toFixed(toFixed);
		}
	}
	if (f) f(e);
};
const onWheelFunc = (e, f) => {
	if (e.target !== document.activeElement) return; //scroll only if focused
	if (f) {
		e.preventDefault();
		e.stopPropagation();
		f(e.deltaY);
	}
};
const disableNewLine = (e, onEnter) => {
	e.stopPropagation();
	if (e.keyCode === 13 && !e.shiftKey) {
		e.preventDefault();
		if (onEnter) onEnter(e);
		return false;
	}
};

const Input = ({
	id = `id_${Math.random()}`,
	refProp = null,
	pattern = null,
	type = "text",
	value,
	placeholder = "",
	disabled,
	onChange = null,
	onBlur = null,
	onEnter = null,
	onWheel = null,
	validation,
	maxLength = 1000000,
	min = null,
	max = null,
	toFixed = null,
	name,
	className,
	style
}) => {
	const props = {
		id,
		"data-lpignore": true,
		autoComplete: "off",
		style: style,
		className: className,
		value: value,
		name: name ? name : `${Date.now()}${Math.random()}`,
		maxLength: maxLength,
		disabled: disabled,
		pattern: pattern,
		type: type,
		placeholder: placeholder,
		ref: refProp,
		onWheel: e => onWheelFunc(e, onWheel),
		onChange: e => onEventValidation(e, validation, onChange, this),
		onBlur: e => onBlurValidation(e, onBlur, min, max, toFixed)
	};
	if (props.type === "textarea") {
		delete props.type;
		return <textarea {...props} onKeyDown={e => disableNewLine(e, onEnter)} />;
	} else {
		return <input {...props} onKeyDown={e => disableNewLine(e, onEnter)} />;
	}
};

export default Input;
